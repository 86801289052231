/*------------------------------------*\
  #SERVICES
\*------------------------------------*/

.services {
  position: relative;
  padding-bottom: 80px;

  @media (min-width: 62em) {
    padding-bottom: 90px;
  }
  @media (min-width: 80em) {
    padding-bottom: 100px;
  }
  @media (min-width: 100em) {
    padding-bottom: 115px;
  }

  &__container.container {

    @media (min-width: 1280px) {
      max-width: 1590px;
      padding-left: 130px;
      padding-right: 100px;
    }
  }

  &__title {
    margin-bottom: 36px;

    @media (min-width: 62em) {
      margin-top: 24px;
    }

    @media (min-width: 80em) {
      margin-top: 40px;
    }

    @media (min-width: 1470px) {
      margin-top: 60px;
    }
  }
}