/*------------------------------------*\
  #PRICE
\*------------------------------------*/

.price {
  position: relative;
  padding-bottom: 30px;

  @media (min-width: 62em) {
    padding-bottom: 50px;
  }
  @media (min-width: 80em) {
    padding-bottom: 75px;
  }
  @media (min-width: 100em) {
    padding-bottom: 85px;
  }
  
  &__title {
    margin-bottom: 40px;

    @media (min-width: 62em) {
      margin-bottom: 60px;
    }
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -15px;
    margin-right: -15px;

    @media (min-width: 80em) {
      margin-left: -2vw;
      margin-right: -2vw;
    }
  }

  &__col {
    width: 100%;
    margin-bottom: 50px;
    padding-left: 15px;
    padding-right: 15px;
    flex: 1 1 320px;
    max-width: 320px;

    @media (min-width: 80em) {
      padding-left: 2vw;
      padding-right: 2vw;
      flex: 1 1 25%;
      max-width: 25%;
    }
  }
}

.price-item {
  text-align: center;
  
  img {
    display: block;
    height: 200px;
    width: 100%;
    margin-bottom: 30px;
    object-fit: cover;
    filter: grayscale(1);
    transition: filter 0.25s ease-out;
  }

  &:hover img {
    filter: grayscale(0);
  }

  &__title {
    margin-bottom: 16px;
    font-weight: bold;
    font-size: rem(14);
    line-height: 1;
    letter-spacing: 0.2em;
    color: $black;
    text-transform: uppercase;
  }

  &__cost {
    margin-bottom: 30px;
    font-weight: bold;
    font-size: rem(36);
    line-height: 1;
    letter-spacing: 0.075em;
    color: $brand;

    &-money {
      display: block;
      margin-top: 3px;
      font-weight: normal;
      font-size: rem(16);
      letter-spacing: 0.2em;
      color: $grey-l;
      opacity: 0.5;
    }
  }

  &__more {
    display: block;
    margin-bottom: 14px;
    font-weight: bold;
    font-size: rem(14);
    line-height: 1;
    letter-spacing: 0.2em;
    color: $black;
    text-transform: uppercase;

  }

  .button {
    width: 100%;
    max-width: 220px;
  }
}