/*------------------------------------*\
  #OFFERS-N-CONTACT
\*------------------------------------*/

.offers-n-contact {
  padding-top: 70px;
  padding-bottom: 64px;

  @media (min-width: 62em) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  @media (min-width: 80em) {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  @media (min-width: 100em) {
    padding-top: 164px;
    padding-bottom: 185px;
  }
}

.offers {
  position: relative;
  padding-bottom: 60px;
  margin-bottom: auto;

  @media (min-width: 62em) {
    padding-top: 50px;
  }
  @media (min-width: 80em) {
    padding-top: 70px;
    padding-bottom: 250px;
  }
  @media (min-width: 100em) {
    padding-top: 90px;
  }

  &__unit {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__title {
    margin-bottom: 24px;

    @media (min-width: 62em) {
      position: relative;
      left: -70px;
      white-space: nowrap;
    }
  }

  &__desc.inner-block {
    position: relative;
    // padding-left: 115px;

    @media (min-width: 48em) {
      padding-left: 9.375vw;
      padding-right: 7vw;
    }

    @media (min-width: 62em) {
      padding-left: 5.83vw;
    }
  }

  &-img-1 {
    margin-bottom: auto;
    display: none;

    @media (min-width: 62em) {
      display: block;
    }
  }

  &-img-2 {
    text-align: center;

    @media (min-width: 100em) {
      position: absolute;
      top: 0;
      left: 100%;
      transform: translateX(-70px);
      width: 100%;
      max-width: 427px;

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 28%;
        left: 0;
        transform: translateX(-66%);
        // width: 149px;
        width: 7.76vw;
        height: 0;
        border: 1px solid #000;
      }
    }
  }
}

