/*------------------------------------*\
  #MODAL
\*------------------------------------*/

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.93);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.modal__container {
  position: relative;
  background-color: #fff;
  padding: 30px;
  width: 100%;
  max-width: 320px;
  max-height: 100vh;
  border-radius: 4px;
  overflow-y: auto;

  @media (min-width: 992px) {
    max-width: 380px;
  }
}

.modal__close {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  padding: 12px;
  line-height: 1;
  background: transparent;
  border: 0;

  &:focus {
    outline: none;
  }
}

.modal__close:before {
  content: "\2715";
}

.modal__content {
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.8);
}

.answer-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}

.answer-text {
  font-size: 1.2rem;
  text-align: center;
}

@-webkit-keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes mmslideIn {
  from {
    -webkit-transform: translateY(15%);
            transform: translateY(15%);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes mmslideIn {
  from {
    -webkit-transform: translateY(15%);
            transform: translateY(15%);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes mmslideOut {
  from {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
  }
}

@keyframes mmslideOut {
  from {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
  }
}

.modal {
  display: none;
}

.modal.is-open {
  display: block;

  .modal__overlay {
    z-index: 555;
  }
}

.modal[aria-hidden="false"] .modal__overlay {
  -webkit-animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
          animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.modal[aria-hidden="false"] .modal__container {
  -webkit-animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
          animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.modal[aria-hidden="true"] .modal__overlay {
  -webkit-animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
          animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.modal[aria-hidden="true"] .modal__container {
  -webkit-animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
          animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.modal .modal__container,
.modal .modal__overlay {
  will-change: transform;
}


/* #modal--booking
   ========================================================================== */

.modal--booking {

}


.modal--booking .modal__container {
  background-color: transparent;
  max-width: none;
}

.modal--booking .modal__close {
  top: 2rem;
  right: 0.5rem;
  color: #fff;
  font-size: rem(24);

  @media (min-width: 62em) {
    font-size: 1.75rem;
    right: 1rem;
  }
}


.modal--booking .modal__content {
  margin-top: 4rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;

  @media (min-width: 62em) {
    max-width: 690px;
  }
}


.modal-booking__total {
  color: #fff;
  font-size: 1rem;
  margin-bottom: 2.5rem;

  @media (min-width: 62em) {
    margin-bottom: 0;
  }
}


.modal-booking__price {
  margin-bottom: 4px;
  line-height: 1.15;
  letter-spacing: 0.1em;
}


.modal-booking__price--bigger {
  @include fluid-type($min_width, $max_width, 24px, 36px);
  
  letter-spacing: 0.075em;
}