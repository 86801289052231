/*------------------------------------*\
  #CONTENT-IMG
\*------------------------------------*/

.content-img {
  text-align: center;

    @media (max-width: 61.99em) {
      position: relative;
      height: 0;
      width: 100%;
      padding-top: 56.25%;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
}