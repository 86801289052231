/*------------------------------------*\
  #HERO
\*------------------------------------*/

.hero {
  position: relative;

  &__grid {
    display: flex;
    flex-wrap: wrap;
  }

  &__col {
    width: 100%;

    &--left {
      position: relative;

      @media (min-width: 62em) {
        flex: 1 1 54.5%;
        max-width: 54.5%;
        padding-bottom: 70px;
      }
    }

    &--right {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 60px 15px 60px 15px;
      background-color: #000;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      @media (min-width: 62em) {
        justify-content: flex-end;
        align-items: flex-end;
        flex: 1 1 45.5%;
        max-width: 45.5%;
        padding-bottom: 90px;
        background-image: url(../img/general/bg/img-1.jpg);
      }

      @media (min-width: 80em) {
        padding-right: 150px;
      }
  
      @media (min-width: 1470px) {
        align-items: flex-start;
        padding-left: 205px;
        padding-right: 15px;
      }
    }
  }

  &__box {
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
    z-index: 10;

    @media (min-width: 48em) {
      left: 50%;
    }

    @media (min-width: 62em) {
      top: 40%;
      left: 100%;
    }

    @media (min-width: 100em) {
      margin-top: 20px;
    }

    .button {
      color: rgba(255, 255, 255, 0.5);
      border-color: rgba(255, 255, 255, 0.8);
      transform: scale(0.8);
      transform-origin: left;

      @media (min-width: 48em) {
        transform: scale(1);
      }
    }
  }

  &-slider {
    height: 100vh;
    max-height: 930px;

    &__item {
      position: relative;
      height: 100%;

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.65);
      }
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .slick-list,
    .slick-track,
    .slick-slide {
      height: 100%;
    }

    .slick-slide > div {
      height: 100%;
    }

    &__dots {
      writing-mode: vertical-lr;
      display: flex;
      align-items: center;
      position: absolute;
      left: 15px;
      bottom: 50px;

      @media (min-width: 62em) {
        top: 50%;
        bottom: auto;
        transform: translateY(-50%);
      }

      @media (min-width: 80em) {
        top: 50%;
        left: 112px;
      }

      @media (min-width: 1470px) {
        left: auto;
        right: 705px;
      }

      .slick-dots {
        line-height: 1px;
  
        li {
          display: inline-block;
          margin-bottom: 18px;

          @media (min-width: 62em) {
            margin-bottom: 24px;
          }
          @media (min-width: 80em) {
            margin-bottom: 32px;
          }
          @media (min-width: 100em) {
            margin-bottom: 40px;
          }
        }
  
        button {
          width: 10px;
          height: 10px;
          padding: 0;
          border: 0;
          border-radius: 50%;
          font-size: 0;
          background: #fff;
          opacity: 0.37;
          outline: none;
          cursor: pointer;

          @media (min-width: 62em) {
            width: 10px;
            height: 10px;
          }
        }
  
        .slick-active {
  
          button {
            background: $brand;
            opacity: 1;
          }
        }
      }
    }
  }

  .slick-counter {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    font-weight: bold;
    font-size: rem(24);
    line-height: 1;
    letter-spacing: 0.1em;
    color: $brand;

    @media (min-width: 36em) {
      font-size: rem(32);
    }
    @media (min-width: 62em) {
      margin-bottom: 50px;
      font-size: rem(40);
    }
    @media (min-width: 80em) {
      margin-bottom: 74px;
      font-size: rem(48);
    }
    @media (min-width: 100em) {
      margin-bottom: 84px;
      font-size: rem(52);
    }

    &:before {
      content: '';
      flex: none;
      display: block;
      width: 0;
      height: 24px;
      border-left: 3px solid $brand;
      margin-bottom: 12px;
      
      @media (min-width: 48em) {
        height: 36px;
      }
      @media (min-width: 80em) {
        height: 46px;
        margin-bottom: 16px;
        border-width: 4px;
      }
      @media (min-width: 100em) {
        height: 51px;
      }
    }
  }

  &__down.icon {
    position: absolute;
    bottom: 18.5vh;
    left: 0;
    transform: translateX(-50%);
    z-index: 10;
    display: none;

    @media (min-width: 62em) {
      display: block;
    }
  }
}


.hero__title {
  @include fluid-type($min_width, $max_width, 20px, 36px);

  margin-top: 1em;
  margin-bottom: 1.5em;
  font-weight: bold;
  line-height: 1.15;
  letter-spacing: 0.07em;
  color: $brand;
  text-transform: uppercase;
  white-space: nowrap;

  @media (min-width: 62em) {
    margin-top: 1.167em;
    margin-bottom: 1.6em;
  }
}


.hero-form {


  &__cost {
    margin-left: 70px;
    margin-bottom: 28px;
    color: $brand;
    text-transform: uppercase;

    @media (min-width: 80em) {
      margin-bottom: 50px;
    }
  }


  &__cost-value {
    @include fluid-type($min_width, $max_width, 18px, 30px);

    display: block;
    font-weight: bold;
    line-height: 1.15;
    letter-spacing: 0.07em;
    white-space: nowrap;
  }


  &__cost-notes {
    display: block;
    font-size: rem(14);
    line-height: 1.2;
    letter-spacing: 0.21em;

  }

  .form-row {
    justify-content: flex-end;
  }

  &__label {
    display: block;
    margin-right: 24px;
    color: #fff;
  }

  .form-control__wrapper,
  .button {
    width: 230px;
  }

  .form-control__wrapper {
    position: relative;

    .icon {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
    }
  }

  .form-input {
    padding-left: 20px;
    font-weight: bold;
    font-size: rem(14);
    letter-spacing: 0.2em;
    color: #fff;
    text-transform: uppercase;
  }
}