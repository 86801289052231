/*------------------------------------------*/
/*  BASIC TYPOGRAPHY STYLES
/*------------------------------------------*/
html {
  font-size: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body, button, input, select, textarea,
.pure-g [class *= "pure-u"] {
  font-family: $main-font;
  font-weight: normal;
  font-size: rem(15);
  line-height: 1.6;
  letter-spacing: 0.025em;
  color: $grey-l;
}
