/*------------------------------------------*/
/*  BASE STYLES
/*------------------------------------------*/
html {
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

html, body {
  min-height: 100vh;
  overflow-x: hidden;

  &.is-overflowed {
    overflow: hidden;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

@-ms-viewport {
  width: device-width;
}

body {
  position: relative;
  width: 100%;
  min-width: 320px;
  background-color: #fff;
}

/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * Vendor-prefixed and regular ::selection selectors cannot be combined:
 * https://stackoverflow.com/a/16982510/7133471
 *
 * Customize the background color to match your design.
 */

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

/*
* A better looking default horizontal rule
*/

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

/*
* Remove the gap between audio, canvas, iframes,
* images, videos and the bottom of their containers:
* https://github.com/h5bp/html5-boilerplate/issues/440
*/

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/*
* Remove default fieldset styles.
*/

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/*
* Allow only vertical resizing of textareas.
*/

textarea {
  resize: vertical;
}

a {
  color: inherit;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: inherit;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border-style: none;
}

/**
 * Fix placeholder font properties inheritance.
 */
::-webkit-input-placeholder {
  font: inherit;
  color: $grey-l;
  opacity: 0.5;
}

:-ms-input-placeholder {
  font: inherit;
  color: $grey-l;
  opacity: 0.5;
}

::-ms-input-placeholder {
  font: inherit;
  color: $grey-l;
  opacity: 0.5;
}

::placeholder {
  font: inherit;
  color: $grey-l;
  opacity: 0.5;
}

input:focus::placeholder,
textarea:focus::placeholder {
  opacity: 0;
}

/**
 * Clickable labels
 */
label[for] {
  cursor: pointer;
}
