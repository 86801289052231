/*------------------------------------*\
  #ABOUT
\*------------------------------------*/

.about {
  position: relative;
  padding-top: 60px;
  padding-bottom: 80px;

  // @media (min-width: 62em) {
  //   padding-bottom: 80px;
  // }
  @media (min-width: 80em) {
    padding-bottom: 100px;
  }
  @media (min-width: 100em) {
    padding-bottom: 140px;
  }

  &__grid {
    margin-bottom: 60px;

    @media (min-width: 62em) {
      margin-bottom: 80px;
    }

    @media (min-width: 100em) {
      margin-bottom: 100px;
    }
  }

  &__title {
    margin-bottom: 10px;

    @media (min-width: 62em) {
      padding-top: 24px;
    }
  }
}

.about__features {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 35px;
  margin-left: -40px;
  margin-right: -40px;

  @media (min-width: 62em) {
    margin-bottom: 65px;
  }
}

.about-feature {
  margin: 0 40px 30px 40px;
  text-align: center;

  &__icon {
    display: inline-flex;
    align-items: flex-end;
    height: 62px;
    margin-bottom: 24px;
  }

  &__title {
    display: block;
    font-weight: bold;
    font-size: rem(14);
    line-height: 1.4;
    letter-spacing: 0.2em;
    color: $black;
    text-transform: uppercase;
  }

  &__notes {
    display: block;
    font-size: rem(16);
    line-height: 1.4;
    letter-spacing: normal;
    color: $grey-l;
    opacity: 0.5;
  }
}

.about-booking {

  &__content {
    padding-top: 40px;

    @media (min-width: 62em) {
      // padding: 40px 70px 0 64px;
      padding: 40px 3.6458vw 0 3.333vw;
    }
    @media (min-width: 80em) {
      padding-top: 60px;
    }
    @media (min-width: 100em) {
      padding-top: 90px;
    }
  }

  &__desc {
    max-width: 300px;
    margin-bottom: 24px;
  }

  &__schedule {
    margin-bottom: 40px;
    font-weight: bold;

    @media (min-width: 62em) {
      margin-bottom: 60px;
    }
  }

  .button {
    width: 318px;
  }
}


/* #about--room
   ========================================================================== */

.about--room {
  padding-bottom: 34px;

  @media (min-width: 80em) {
    padding-bottom: 100px;
  }
  @media (min-width: 100em) {
    padding-bottom: 110px;
  }
}


.about--room .about__features {
  margin-bottom: 0;
}