/*------------------------------------*\
  #HEADER
\*------------------------------------*/

.header {
  position: relative;
  margin-bottom: -100px;
  height: 100px;
  padding-top: 36px;
  z-index: 100;

  &__nav {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  &__contacts {
    font-weight: bold;
    font-size: rem(15);
    line-height: 1.2;
    text-transform: uppercase;

    @media (min-width: 62em) {
      font-size: rem(15);
    }

    &--main {
      margin-left: auto;
      font-size: rem(13);
      color: $brand;
      text-align: right;

      @media (min-width: 62em) {
        font-size: rem(15);
      }
    }
  }
}