/*------------------------------------*\
  #DESC
\*------------------------------------*/

.inner-block {
  // padding-left: 180px;
  // padding-right: 50px;

  @media (min-width: 48em) {
    padding-left: 9.375vw;
    padding-right: 2.6vw;
  }

  > *:not(h2):not(h3):not(form) {
    margin-bottom: 26px;
  }

  h3 {
    font-weight: bold;
    font-size: rem(16);
    line-height: 1.4;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: $black;
  }
}