/*------------------------------------*\
  #FONTS
\*------------------------------------*/
@font-face {
  font-family: 'Proxima Nova';
  src: url("../fonts/ProximaNova-Bold.woff2") format("woff2"), url("../fonts/ProximaNova-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Proxima Nova';
  src: url("../fonts/ProximaNova-Regular.woff2") format("woff2"), url("../fonts/ProximaNova-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

/*------------------------------------*\
  #RESET STYLES
\*------------------------------------*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

ul,
ol {
  list-style: none; }

/*------------------------------------------*/
/*  BASE STYLES
/*------------------------------------------*/
html {
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

html, body {
  min-height: 100vh;
  overflow-x: hidden; }
  html.is-overflowed, body.is-overflowed {
    overflow: hidden; }

*,
*::before,
*::after {
  box-sizing: inherit; }

@-ms-viewport {
  width: device-width; }

body {
  position: relative;
  width: 100%;
  min-width: 320px;
  background-color: #fff; }

/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * Vendor-prefixed and regular ::selection selectors cannot be combined:
 * https://stackoverflow.com/a/16982510/7133471
 *
 * Customize the background color to match your design.
 */
::-moz-selection {
  background: #b3d4fc;
  text-shadow: none; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

/*
* A better looking default horizontal rule
*/
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

/*
* Remove the gap between audio, canvas, iframes,
* images, videos and the bottom of their containers:
* https://github.com/h5bp/html5-boilerplate/issues/440
*/
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

/*
* Remove default fieldset styles.
*/
fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

/*
* Allow only vertical resizing of textareas.
*/
textarea {
  resize: vertical; }

a {
  color: inherit;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }

a:hover {
  color: inherit; }

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border-style: none; }

/**
 * Fix placeholder font properties inheritance.
 */
::-webkit-input-placeholder {
  font: inherit;
  color: #464646;
  opacity: 0.5; }

:-ms-input-placeholder {
  font: inherit;
  color: #464646;
  opacity: 0.5; }

::-ms-input-placeholder {
  font: inherit;
  color: #464646;
  opacity: 0.5; }

::placeholder {
  font: inherit;
  color: #464646;
  opacity: 0.5; }

input:focus::placeholder,
textarea:focus::placeholder {
  opacity: 0; }

/**
 * Clickable labels
 */
label[for] {
  cursor: pointer; }

/*------------------------------------------*/
/*  #GRID
/*------------------------------------------*/
/*!
Pure v1.0.0
Copyright 2013 Yahoo!
Licensed under the BSD License.
https://github.com/yahoo/pure/blob/master/LICENSE.md
*/
.pure-g {
  letter-spacing: -.31em;
  text-rendering: optimizespeed;
  font-family: FreeSans,Arimo,"Droid Sans",Helvetica,Arial,sans-serif;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start; }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  table .pure-g {
    display: block; } }

.opera-only :-o-prefocus, .pure-g {
  word-spacing: -.43em; }

.pure-u, .pure-u-1, .pure-u-1-1, .pure-u-1-12, .pure-u-1-2, .pure-u-1-24, .pure-u-1-3, .pure-u-1-4, .pure-u-1-5, .pure-u-1-6, .pure-u-1-8, .pure-u-10-24, .pure-u-11-12, .pure-u-11-24, .pure-u-12-24, .pure-u-13-24, .pure-u-14-24, .pure-u-15-24, .pure-u-16-24, .pure-u-17-24, .pure-u-18-24, .pure-u-19-24, .pure-u-2-24, .pure-u-2-3, .pure-u-2-5, .pure-u-20-24, .pure-u-21-24, .pure-u-22-24, .pure-u-23-24, .pure-u-24-24, .pure-u-3-24, .pure-u-3-4, .pure-u-3-5, .pure-u-3-8, .pure-u-4-24, .pure-u-4-5, .pure-u-5-12, .pure-u-5-24, .pure-u-5-5, .pure-u-5-6, .pure-u-5-8, .pure-u-6-24, .pure-u-7-12, .pure-u-7-24, .pure-u-7-8, .pure-u-8-24, .pure-u-9-24 {
  display: inline-block;
  zoom: 1;
  letter-spacing: normal;
  word-spacing: normal;
  vertical-align: top;
  text-rendering: auto; }

.pure-g [class*=pure-u] {
  font-family: sans-serif; }

.pure-u-1-24 {
  width: 4.1667%; }

.pure-u-1-12, .pure-u-2-24 {
  width: 8.3333%; }

.pure-u-1-8, .pure-u-3-24 {
  width: 12.5%; }

.pure-u-1-6, .pure-u-4-24 {
  width: 16.6667%; }

.pure-u-1-5 {
  width: 20%; }

.pure-u-5-24 {
  width: 20.8333%; }

.pure-u-1-4, .pure-u-6-24 {
  width: 25%; }

.pure-u-7-24 {
  width: 29.1667%; }

.pure-u-1-3, .pure-u-8-24 {
  width: 33.3333%; }

.pure-u-3-8, .pure-u-9-24 {
  width: 37.5%; }

.pure-u-2-5 {
  width: 40%; }

.pure-u-10-24, .pure-u-5-12 {
  width: 41.6667%; }

.pure-u-11-24 {
  width: 45.8333%; }

.pure-u-1-2, .pure-u-12-24 {
  width: 50%; }

.pure-u-13-24 {
  width: 54.1667%; }

.pure-u-14-24, .pure-u-7-12 {
  width: 58.3333%; }

.pure-u-3-5 {
  width: 60%; }

.pure-u-15-24, .pure-u-5-8 {
  width: 62.5%; }

.pure-u-16-24, .pure-u-2-3 {
  width: 66.6667%; }

.pure-u-17-24 {
  width: 70.8333%; }

.pure-u-18-24, .pure-u-3-4 {
  width: 75%; }

.pure-u-19-24 {
  width: 79.1667%; }

.pure-u-4-5 {
  width: 80%; }

.pure-u-20-24, .pure-u-5-6 {
  width: 83.3333%; }

.pure-u-21-24, .pure-u-7-8 {
  width: 87.5%; }

.pure-u-11-12, .pure-u-22-24 {
  width: 91.6667%; }

.pure-u-23-24 {
  width: 95.8333%; }

.pure-u-1, .pure-u-1-1, .pure-u-24-24, .pure-u-5-5 {
  width: 100%; }

@media screen and (min-width: 36em) {
  .pure-u-sm-1, .pure-u-sm-1-1, .pure-u-sm-1-12, .pure-u-sm-1-2, .pure-u-sm-1-24, .pure-u-sm-1-3, .pure-u-sm-1-4, .pure-u-sm-1-5, .pure-u-sm-1-6, .pure-u-sm-1-8, .pure-u-sm-10-24, .pure-u-sm-11-12, .pure-u-sm-11-24, .pure-u-sm-12-24, .pure-u-sm-13-24, .pure-u-sm-14-24, .pure-u-sm-15-24, .pure-u-sm-16-24, .pure-u-sm-17-24, .pure-u-sm-18-24, .pure-u-sm-19-24, .pure-u-sm-2-24, .pure-u-sm-2-3, .pure-u-sm-2-5, .pure-u-sm-20-24, .pure-u-sm-21-24, .pure-u-sm-22-24, .pure-u-sm-23-24, .pure-u-sm-24-24, .pure-u-sm-3-24, .pure-u-sm-3-4, .pure-u-sm-3-5, .pure-u-sm-3-8, .pure-u-sm-4-24, .pure-u-sm-4-5, .pure-u-sm-5-12, .pure-u-sm-5-24, .pure-u-sm-5-5, .pure-u-sm-5-6, .pure-u-sm-5-8, .pure-u-sm-6-24, .pure-u-sm-7-12, .pure-u-sm-7-24, .pure-u-sm-7-8, .pure-u-sm-8-24, .pure-u-sm-9-24 {
    display: inline-block;
    zoom: 1;
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    text-rendering: auto; }
  .pure-u-sm-1-24 {
    width: 4.1667%; }
  .pure-u-sm-1-12, .pure-u-sm-2-24 {
    width: 8.3333%; }
  .pure-u-sm-1-8, .pure-u-sm-3-24 {
    width: 12.5%; }
  .pure-u-sm-1-6, .pure-u-sm-4-24 {
    width: 16.6667%; }
  .pure-u-sm-1-5 {
    width: 20%; }
  .pure-u-sm-5-24 {
    width: 20.8333%; }
  .pure-u-sm-1-4, .pure-u-sm-6-24 {
    width: 25%; }
  .pure-u-sm-7-24 {
    width: 29.1667%; }
  .pure-u-sm-1-3, .pure-u-sm-8-24 {
    width: 33.3333%; }
  .pure-u-sm-3-8, .pure-u-sm-9-24 {
    width: 37.5%; }
  .pure-u-sm-2-5 {
    width: 40%; }
  .pure-u-sm-10-24, .pure-u-sm-5-12 {
    width: 41.6667%; }
  .pure-u-sm-11-24 {
    width: 45.8333%; }
  .pure-u-sm-1-2, .pure-u-sm-12-24 {
    width: 50%; }
  .pure-u-sm-13-24 {
    width: 54.1667%; }
  .pure-u-sm-14-24, .pure-u-sm-7-12 {
    width: 58.3333%; }
  .pure-u-sm-3-5 {
    width: 60%; }
  .pure-u-sm-15-24, .pure-u-sm-5-8 {
    width: 62.5%; }
  .pure-u-sm-16-24, .pure-u-sm-2-3 {
    width: 66.6667%; }
  .pure-u-sm-17-24 {
    width: 70.8333%; }
  .pure-u-sm-18-24, .pure-u-sm-3-4 {
    width: 75%; }
  .pure-u-sm-19-24 {
    width: 79.1667%; }
  .pure-u-sm-4-5 {
    width: 80%; }
  .pure-u-sm-20-24, .pure-u-sm-5-6 {
    width: 83.3333%; }
  .pure-u-sm-21-24, .pure-u-sm-7-8 {
    width: 87.5%; }
  .pure-u-sm-11-12, .pure-u-sm-22-24 {
    width: 91.6667%; }
  .pure-u-sm-23-24 {
    width: 95.8333%; }
  .pure-u-sm-1, .pure-u-sm-1-1, .pure-u-sm-24-24, .pure-u-sm-5-5 {
    width: 100%; } }

@media screen and (min-width: 48em) {
  .pure-u-md-1, .pure-u-md-1-1, .pure-u-md-1-12, .pure-u-md-1-2, .pure-u-md-1-24, .pure-u-md-1-3, .pure-u-md-1-4, .pure-u-md-1-5, .pure-u-md-1-6, .pure-u-md-1-8, .pure-u-md-10-24, .pure-u-md-11-12, .pure-u-md-11-24, .pure-u-md-12-24, .pure-u-md-13-24, .pure-u-md-14-24, .pure-u-md-15-24, .pure-u-md-16-24, .pure-u-md-17-24, .pure-u-md-18-24, .pure-u-md-19-24, .pure-u-md-2-24, .pure-u-md-2-3, .pure-u-md-2-5, .pure-u-md-20-24, .pure-u-md-21-24, .pure-u-md-22-24, .pure-u-md-23-24, .pure-u-md-24-24, .pure-u-md-3-24, .pure-u-md-3-4, .pure-u-md-3-5, .pure-u-md-3-8, .pure-u-md-4-24, .pure-u-md-4-5, .pure-u-md-5-12, .pure-u-md-5-24, .pure-u-md-5-5, .pure-u-md-5-6, .pure-u-md-5-8, .pure-u-md-6-24, .pure-u-md-7-12, .pure-u-md-7-24, .pure-u-md-7-8, .pure-u-md-8-24, .pure-u-md-9-24 {
    display: inline-block;
    zoom: 1;
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    text-rendering: auto; }
  .pure-u-md-1-24 {
    width: 4.1667%; }
  .pure-u-md-1-12, .pure-u-md-2-24 {
    width: 8.3333%; }
  .pure-u-md-1-8, .pure-u-md-3-24 {
    width: 12.5%; }
  .pure-u-md-1-6, .pure-u-md-4-24 {
    width: 16.6667%; }
  .pure-u-md-1-5 {
    width: 20%; }
  .pure-u-md-5-24 {
    width: 20.8333%; }
  .pure-u-md-1-4, .pure-u-md-6-24 {
    width: 25%; }
  .pure-u-md-7-24 {
    width: 29.1667%; }
  .pure-u-md-1-3, .pure-u-md-8-24 {
    width: 33.3333%; }
  .pure-u-md-3-8, .pure-u-md-9-24 {
    width: 37.5%; }
  .pure-u-md-2-5 {
    width: 40%; }
  .pure-u-md-10-24, .pure-u-md-5-12 {
    width: 41.6667%; }
  .pure-u-md-11-24 {
    width: 45.8333%; }
  .pure-u-md-1-2, .pure-u-md-12-24 {
    width: 50%; }
  .pure-u-md-13-24 {
    width: 54.1667%; }
  .pure-u-md-14-24, .pure-u-md-7-12 {
    width: 58.3333%; }
  .pure-u-md-3-5 {
    width: 60%; }
  .pure-u-md-15-24, .pure-u-md-5-8 {
    width: 62.5%; }
  .pure-u-md-16-24, .pure-u-md-2-3 {
    width: 66.6667%; }
  .pure-u-md-17-24 {
    width: 70.8333%; }
  .pure-u-md-18-24, .pure-u-md-3-4 {
    width: 75%; }
  .pure-u-md-19-24 {
    width: 79.1667%; }
  .pure-u-md-4-5 {
    width: 80%; }
  .pure-u-md-20-24, .pure-u-md-5-6 {
    width: 83.3333%; }
  .pure-u-md-21-24, .pure-u-md-7-8 {
    width: 87.5%; }
  .pure-u-md-11-12, .pure-u-md-22-24 {
    width: 91.6667%; }
  .pure-u-md-23-24 {
    width: 95.8333%; }
  .pure-u-md-1, .pure-u-md-1-1, .pure-u-md-24-24, .pure-u-md-5-5 {
    width: 100%; } }

@media screen and (min-width: 62em) {
  .pure-u-lg-1, .pure-u-lg-1-1, .pure-u-lg-1-12, .pure-u-lg-1-2, .pure-u-lg-1-24, .pure-u-lg-1-3, .pure-u-lg-1-4, .pure-u-lg-1-5, .pure-u-lg-1-6, .pure-u-lg-1-8, .pure-u-lg-10-24, .pure-u-lg-11-12, .pure-u-lg-11-24, .pure-u-lg-12-24, .pure-u-lg-13-24, .pure-u-lg-14-24, .pure-u-lg-15-24, .pure-u-lg-16-24, .pure-u-lg-17-24, .pure-u-lg-18-24, .pure-u-lg-19-24, .pure-u-lg-2-24, .pure-u-lg-2-3, .pure-u-lg-2-5, .pure-u-lg-20-24, .pure-u-lg-21-24, .pure-u-lg-22-24, .pure-u-lg-23-24, .pure-u-lg-24-24, .pure-u-lg-3-24, .pure-u-lg-3-4, .pure-u-lg-3-5, .pure-u-lg-3-8, .pure-u-lg-4-24, .pure-u-lg-4-5, .pure-u-lg-5-12, .pure-u-lg-5-24, .pure-u-lg-5-5, .pure-u-lg-5-6, .pure-u-lg-5-8, .pure-u-lg-6-24, .pure-u-lg-7-12, .pure-u-lg-7-24, .pure-u-lg-7-8, .pure-u-lg-8-24, .pure-u-lg-9-24 {
    display: inline-block;
    zoom: 1;
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    text-rendering: auto; }
  .pure-u-lg-1-24 {
    width: 4.1667%; }
  .pure-u-lg-1-12, .pure-u-lg-2-24 {
    width: 8.3333%; }
  .pure-u-lg-1-8, .pure-u-lg-3-24 {
    width: 12.5%; }
  .pure-u-lg-1-6, .pure-u-lg-4-24 {
    width: 16.6667%; }
  .pure-u-lg-1-5 {
    width: 20%; }
  .pure-u-lg-5-24 {
    width: 20.8333%; }
  .pure-u-lg-1-4, .pure-u-lg-6-24 {
    width: 25%; }
  .pure-u-lg-7-24 {
    width: 29.1667%; }
  .pure-u-lg-1-3, .pure-u-lg-8-24 {
    width: 33.3333%; }
  .pure-u-lg-3-8, .pure-u-lg-9-24 {
    width: 37.5%; }
  .pure-u-lg-2-5 {
    width: 40%; }
  .pure-u-lg-10-24, .pure-u-lg-5-12 {
    width: 41.6667%; }
  .pure-u-lg-11-24 {
    width: 45.8333%; }
  .pure-u-lg-1-2, .pure-u-lg-12-24 {
    width: 50%; }
  .pure-u-lg-13-24 {
    width: 54.1667%; }
  .pure-u-lg-14-24, .pure-u-lg-7-12 {
    width: 58.3333%; }
  .pure-u-lg-3-5 {
    width: 60%; }
  .pure-u-lg-15-24, .pure-u-lg-5-8 {
    width: 62.5%; }
  .pure-u-lg-16-24, .pure-u-lg-2-3 {
    width: 66.6667%; }
  .pure-u-lg-17-24 {
    width: 70.8333%; }
  .pure-u-lg-18-24, .pure-u-lg-3-4 {
    width: 75%; }
  .pure-u-lg-19-24 {
    width: 79.1667%; }
  .pure-u-lg-4-5 {
    width: 80%; }
  .pure-u-lg-20-24, .pure-u-lg-5-6 {
    width: 83.3333%; }
  .pure-u-lg-21-24, .pure-u-lg-7-8 {
    width: 87.5%; }
  .pure-u-lg-11-12, .pure-u-lg-22-24 {
    width: 91.6667%; }
  .pure-u-lg-23-24 {
    width: 95.8333%; }
  .pure-u-lg-1, .pure-u-lg-1-1, .pure-u-lg-24-24, .pure-u-lg-5-5 {
    width: 100%; } }

@media screen and (min-width: 80em) {
  .pure-u-xl-1, .pure-u-xl-1-1, .pure-u-xl-1-12, .pure-u-xl-1-2, .pure-u-xl-1-24, .pure-u-xl-1-3, .pure-u-xl-1-4, .pure-u-xl-1-5, .pure-u-xl-1-6, .pure-u-xl-1-8, .pure-u-xl-10-24, .pure-u-xl-11-12, .pure-u-xl-11-24, .pure-u-xl-12-24, .pure-u-xl-13-24, .pure-u-xl-14-24, .pure-u-xl-15-24, .pure-u-xl-16-24, .pure-u-xl-17-24, .pure-u-xl-18-24, .pure-u-xl-19-24, .pure-u-xl-2-24, .pure-u-xl-2-3, .pure-u-xl-2-5, .pure-u-xl-20-24, .pure-u-xl-21-24, .pure-u-xl-22-24, .pure-u-xl-23-24, .pure-u-xl-24-24, .pure-u-xl-3-24, .pure-u-xl-3-4, .pure-u-xl-3-5, .pure-u-xl-3-8, .pure-u-xl-4-24, .pure-u-xl-4-5, .pure-u-xl-5-12, .pure-u-xl-5-24, .pure-u-xl-5-5, .pure-u-xl-5-6, .pure-u-xl-5-8, .pure-u-xl-6-24, .pure-u-xl-7-12, .pure-u-xl-7-24, .pure-u-xl-7-8, .pure-u-xl-8-24, .pure-u-xl-9-24 {
    display: inline-block;
    zoom: 1;
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    text-rendering: auto; }
  .pure-u-xl-1-24 {
    width: 4.1667%; }
  .pure-u-xl-1-12, .pure-u-xl-2-24 {
    width: 8.3333%; }
  .pure-u-xl-1-8, .pure-u-xl-3-24 {
    width: 12.5%; }
  .pure-u-xl-1-6, .pure-u-xl-4-24 {
    width: 16.6667%; }
  .pure-u-xl-1-5 {
    width: 20%; }
  .pure-u-xl-5-24 {
    width: 20.8333%; }
  .pure-u-xl-1-4, .pure-u-xl-6-24 {
    width: 25%; }
  .pure-u-xl-7-24 {
    width: 29.1667%; }
  .pure-u-xl-1-3, .pure-u-xl-8-24 {
    width: 33.3333%; }
  .pure-u-xl-3-8, .pure-u-xl-9-24 {
    width: 37.5%; }
  .pure-u-xl-2-5 {
    width: 40%; }
  .pure-u-xl-10-24, .pure-u-xl-5-12 {
    width: 41.6667%; }
  .pure-u-xl-11-24 {
    width: 45.8333%; }
  .pure-u-xl-1-2, .pure-u-xl-12-24 {
    width: 50%; }
  .pure-u-xl-13-24 {
    width: 54.1667%; }
  .pure-u-xl-14-24, .pure-u-xl-7-12 {
    width: 58.3333%; }
  .pure-u-xl-3-5 {
    width: 60%; }
  .pure-u-xl-15-24, .pure-u-xl-5-8 {
    width: 62.5%; }
  .pure-u-xl-16-24, .pure-u-xl-2-3 {
    width: 66.6667%; }
  .pure-u-xl-17-24 {
    width: 70.8333%; }
  .pure-u-xl-18-24, .pure-u-xl-3-4 {
    width: 75%; }
  .pure-u-xl-19-24 {
    width: 79.1667%; }
  .pure-u-xl-4-5 {
    width: 80%; }
  .pure-u-xl-20-24, .pure-u-xl-5-6 {
    width: 83.3333%; }
  .pure-u-xl-21-24, .pure-u-xl-7-8 {
    width: 87.5%; }
  .pure-u-xl-11-12, .pure-u-xl-22-24 {
    width: 91.6667%; }
  .pure-u-xl-23-24 {
    width: 95.8333%; }
  .pure-u-xl-1, .pure-u-xl-1-1, .pure-u-xl-24-24, .pure-u-xl-5-5 {
    width: 100%; } }

/*------------------------------------------*/
/*  BASIC TYPOGRAPHY STYLES
/*------------------------------------------*/
html {
  font-size: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

body, button, input, select, textarea,
.pure-g [class*="pure-u"] {
  font-family: "Proxima Nova", sans-serif;
  font-weight: normal;
  font-size: 0.9375rem;
  line-height: 1.6;
  letter-spacing: 0.025em;
  color: #464646; }

/*------------------------------------------*/
/*  #HELPERS
/*------------------------------------------*/
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto; }

/*
 * Hide visually and from screen readers
 */
.hidden {
  display: none !important; }

/*
* Hide only visually, but have it available for screen readers:
* https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
*
* 1. For long content, line feeds are not interpreted as spaces and small width
*    causes content to wrap 1 word per line:
*    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
*/
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  /* 1 */ }

/*
* Extends the .visuallyhidden class to allow the element
* to be focusable when navigated to via the keyboard:
* https://www.drupal.org/node/897638
*/
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
  white-space: inherit; }

/*
* Hide visually and from screen readers, but maintain layout
*/
.invisible {
  visibility: hidden; }

/*
* Clearfix: contain floats
*
* For modern browsers
* 1. The space content is one way to avoid an Opera bug when the
*    `contenteditable` attribute is included anywhere else in the document.
*    Otherwise it causes space to appear at the top and bottom of elements
*    that receive the `clearfix` class.
* 2. The use of `table` rather than `block` is only necessary if using
*    `:before` to contain the top-margins of child elements.
*/
.clearfix:before,
.clearfix:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */ }

.clearfix:after {
  clear: both; }

.highlight {
  color: #bea67c !important; }

.overlay {
  position: relative;
  z-index: 0; }
  .overlay::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: -1; }

.link-underline {
  border-bottom: 1px solid;
  border-color: inherit; }

/*------------------------------------*\
  #HEADER
\*------------------------------------*/
.header {
  position: relative;
  margin-bottom: -100px;
  height: 100px;
  padding-top: 36px;
  z-index: 100; }
  .header__nav {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start; }
  .header__contacts {
    font-weight: bold;
    font-size: 0.9375rem;
    line-height: 1.2;
    text-transform: uppercase; }
    @media (min-width: 62em) {
      .header__contacts {
        font-size: 0.9375rem; } }
    .header__contacts--main {
      margin-left: auto;
      font-size: 0.8125rem;
      color: #bea67c;
      text-align: right; }
      @media (min-width: 62em) {
        .header__contacts--main {
          font-size: 0.9375rem; } }

/*------------------------------------*\
  #HERO
\*------------------------------------*/
.hero {
  position: relative; }
  .hero__grid {
    display: flex;
    flex-wrap: wrap; }
  .hero__col {
    width: 100%; }
    .hero__col--left {
      position: relative; }
      @media (min-width: 62em) {
        .hero__col--left {
          flex: 1 1 54.5%;
          max-width: 54.5%;
          padding-bottom: 70px; } }
    .hero__col--right {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 60px 15px 60px 15px;
      background-color: #000;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover; }
      @media (min-width: 62em) {
        .hero__col--right {
          justify-content: flex-end;
          align-items: flex-end;
          flex: 1 1 45.5%;
          max-width: 45.5%;
          padding-bottom: 90px;
          background-image: url(../img/general/bg/img-1.jpg); } }
      @media (min-width: 80em) {
        .hero__col--right {
          padding-right: 150px; } }
      @media (min-width: 1470px) {
        .hero__col--right {
          align-items: flex-start;
          padding-left: 205px;
          padding-right: 15px; } }
  .hero__box {
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
    z-index: 10; }
    @media (min-width: 48em) {
      .hero__box {
        left: 50%; } }
    @media (min-width: 62em) {
      .hero__box {
        top: 40%;
        left: 100%; } }
    @media (min-width: 100em) {
      .hero__box {
        margin-top: 20px; } }
    .hero__box .button {
      color: rgba(255, 255, 255, 0.5);
      border-color: rgba(255, 255, 255, 0.8);
      transform: scale(0.8);
      transform-origin: left; }
      @media (min-width: 48em) {
        .hero__box .button {
          transform: scale(1); } }
  .hero-slider {
    height: 100vh;
    max-height: 930px; }
    .hero-slider__item {
      position: relative;
      height: 100%; }
      .hero-slider__item::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.65); }
    .hero-slider img {
      height: 100%;
      width: 100%;
      object-fit: cover; }
    .hero-slider .slick-list,
    .hero-slider .slick-track,
    .hero-slider .slick-slide {
      height: 100%; }
    .hero-slider .slick-slide > div {
      height: 100%; }
    .hero-slider__dots {
      writing-mode: vertical-lr;
      display: flex;
      align-items: center;
      position: absolute;
      left: 15px;
      bottom: 50px; }
      @media (min-width: 62em) {
        .hero-slider__dots {
          top: 50%;
          bottom: auto;
          transform: translateY(-50%); } }
      @media (min-width: 80em) {
        .hero-slider__dots {
          top: 50%;
          left: 112px; } }
      @media (min-width: 1470px) {
        .hero-slider__dots {
          left: auto;
          right: 705px; } }
      .hero-slider__dots .slick-dots {
        line-height: 1px; }
        .hero-slider__dots .slick-dots li {
          display: inline-block;
          margin-bottom: 18px; }
          @media (min-width: 62em) {
            .hero-slider__dots .slick-dots li {
              margin-bottom: 24px; } }
          @media (min-width: 80em) {
            .hero-slider__dots .slick-dots li {
              margin-bottom: 32px; } }
          @media (min-width: 100em) {
            .hero-slider__dots .slick-dots li {
              margin-bottom: 40px; } }
        .hero-slider__dots .slick-dots button {
          width: 10px;
          height: 10px;
          padding: 0;
          border: 0;
          border-radius: 50%;
          font-size: 0;
          background: #fff;
          opacity: 0.37;
          outline: none;
          cursor: pointer; }
          @media (min-width: 62em) {
            .hero-slider__dots .slick-dots button {
              width: 10px;
              height: 10px; } }
        .hero-slider__dots .slick-dots .slick-active button {
          background: #bea67c;
          opacity: 1; }
  .hero .slick-counter {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1;
    letter-spacing: 0.1em;
    color: #bea67c; }
    @media (min-width: 36em) {
      .hero .slick-counter {
        font-size: 2rem; } }
    @media (min-width: 62em) {
      .hero .slick-counter {
        margin-bottom: 50px;
        font-size: 2.5rem; } }
    @media (min-width: 80em) {
      .hero .slick-counter {
        margin-bottom: 74px;
        font-size: 3rem; } }
    @media (min-width: 100em) {
      .hero .slick-counter {
        margin-bottom: 84px;
        font-size: 3.25rem; } }
    .hero .slick-counter:before {
      content: '';
      flex: none;
      display: block;
      width: 0;
      height: 24px;
      border-left: 3px solid #bea67c;
      margin-bottom: 12px; }
      @media (min-width: 48em) {
        .hero .slick-counter:before {
          height: 36px; } }
      @media (min-width: 80em) {
        .hero .slick-counter:before {
          height: 46px;
          margin-bottom: 16px;
          border-width: 4px; } }
      @media (min-width: 100em) {
        .hero .slick-counter:before {
          height: 51px; } }
  .hero__down.icon {
    position: absolute;
    bottom: 18.5vh;
    left: 0;
    transform: translateX(-50%);
    z-index: 10;
    display: none; }
    @media (min-width: 62em) {
      .hero__down.icon {
        display: block; } }

.hero__title {
  margin-top: 1em;
  margin-bottom: 1.5em;
  font-weight: bold;
  line-height: 1.15;
  letter-spacing: 0.07em;
  color: #bea67c;
  text-transform: uppercase;
  white-space: nowrap; }
  .hero__title {
    font-size: 20px; }
    @media screen and (min-width: 320px) {
      .hero__title {
        font-size: calc(20px + 16 * ((100vw - 320px) / 1600)); } }
    @media screen and (min-width: 1920px) {
      .hero__title {
        font-size: 36px; } }
  @media (min-width: 62em) {
    .hero__title {
      margin-top: 1.167em;
      margin-bottom: 1.6em; } }

.hero-form__cost {
  margin-left: 70px;
  margin-bottom: 28px;
  color: #bea67c;
  text-transform: uppercase; }
  @media (min-width: 80em) {
    .hero-form__cost {
      margin-bottom: 50px; } }

.hero-form__cost-value {
  display: block;
  font-weight: bold;
  line-height: 1.15;
  letter-spacing: 0.07em;
  white-space: nowrap; }
  .hero-form__cost-value {
    font-size: 18px; }
    @media screen and (min-width: 320px) {
      .hero-form__cost-value {
        font-size: calc(18px + 12 * ((100vw - 320px) / 1600)); } }
    @media screen and (min-width: 1920px) {
      .hero-form__cost-value {
        font-size: 30px; } }

.hero-form__cost-notes {
  display: block;
  font-size: 0.875rem;
  line-height: 1.2;
  letter-spacing: 0.21em; }

.hero-form .form-row {
  justify-content: flex-end; }

.hero-form__label {
  display: block;
  margin-right: 24px;
  color: #fff; }

.hero-form .form-control__wrapper,
.hero-form .button {
  width: 230px; }

.hero-form .form-control__wrapper {
  position: relative; }
  .hero-form .form-control__wrapper .icon {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%); }

.hero-form .form-input {
  padding-left: 20px;
  font-weight: bold;
  font-size: 0.875rem;
  letter-spacing: 0.2em;
  color: #fff;
  text-transform: uppercase; }

/*------------------------------------*\
  #ABOUT
\*------------------------------------*/
.about {
  position: relative;
  padding-top: 60px;
  padding-bottom: 80px; }
  @media (min-width: 80em) {
    .about {
      padding-bottom: 100px; } }
  @media (min-width: 100em) {
    .about {
      padding-bottom: 140px; } }
  .about__grid {
    margin-bottom: 60px; }
    @media (min-width: 62em) {
      .about__grid {
        margin-bottom: 80px; } }
    @media (min-width: 100em) {
      .about__grid {
        margin-bottom: 100px; } }
  .about__title {
    margin-bottom: 10px; }
    @media (min-width: 62em) {
      .about__title {
        padding-top: 24px; } }

.about__features {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 35px;
  margin-left: -40px;
  margin-right: -40px; }
  @media (min-width: 62em) {
    .about__features {
      margin-bottom: 65px; } }

.about-feature {
  margin: 0 40px 30px 40px;
  text-align: center; }
  .about-feature__icon {
    display: inline-flex;
    align-items: flex-end;
    height: 62px;
    margin-bottom: 24px; }
  .about-feature__title {
    display: block;
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 1.4;
    letter-spacing: 0.2em;
    color: #010101;
    text-transform: uppercase; }
  .about-feature__notes {
    display: block;
    font-size: 1rem;
    line-height: 1.4;
    letter-spacing: normal;
    color: #464646;
    opacity: 0.5; }

.about-booking__content {
  padding-top: 40px; }
  @media (min-width: 62em) {
    .about-booking__content {
      padding: 40px 3.6458vw 0 3.333vw; } }
  @media (min-width: 80em) {
    .about-booking__content {
      padding-top: 60px; } }
  @media (min-width: 100em) {
    .about-booking__content {
      padding-top: 90px; } }

.about-booking__desc {
  max-width: 300px;
  margin-bottom: 24px; }

.about-booking__schedule {
  margin-bottom: 40px;
  font-weight: bold; }
  @media (min-width: 62em) {
    .about-booking__schedule {
      margin-bottom: 60px; } }

.about-booking .button {
  width: 318px; }

/* #about--room
   ========================================================================== */
.about--room {
  padding-bottom: 34px; }
  @media (min-width: 80em) {
    .about--room {
      padding-bottom: 100px; } }
  @media (min-width: 100em) {
    .about--room {
      padding-bottom: 110px; } }

.about--room .about__features {
  margin-bottom: 0; }

/*------------------------------------*\
  #PRICE
\*------------------------------------*/
.price {
  position: relative;
  padding-bottom: 30px; }
  @media (min-width: 62em) {
    .price {
      padding-bottom: 50px; } }
  @media (min-width: 80em) {
    .price {
      padding-bottom: 75px; } }
  @media (min-width: 100em) {
    .price {
      padding-bottom: 85px; } }
  .price__title {
    margin-bottom: 40px; }
    @media (min-width: 62em) {
      .price__title {
        margin-bottom: 60px; } }
  .price__grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -15px;
    margin-right: -15px; }
    @media (min-width: 80em) {
      .price__grid {
        margin-left: -2vw;
        margin-right: -2vw; } }
  .price__col {
    width: 100%;
    margin-bottom: 50px;
    padding-left: 15px;
    padding-right: 15px;
    flex: 1 1 320px;
    max-width: 320px; }
    @media (min-width: 80em) {
      .price__col {
        padding-left: 2vw;
        padding-right: 2vw;
        flex: 1 1 25%;
        max-width: 25%; } }

.price-item {
  text-align: center; }
  .price-item img {
    display: block;
    height: 200px;
    width: 100%;
    margin-bottom: 30px;
    object-fit: cover;
    filter: grayscale(1);
    transition: filter 0.25s ease-out; }
  .price-item:hover img {
    filter: grayscale(0); }
  .price-item__title {
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 1;
    letter-spacing: 0.2em;
    color: #010101;
    text-transform: uppercase; }
  .price-item__cost {
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 2.25rem;
    line-height: 1;
    letter-spacing: 0.075em;
    color: #bea67c; }
    .price-item__cost-money {
      display: block;
      margin-top: 3px;
      font-weight: normal;
      font-size: 1rem;
      letter-spacing: 0.2em;
      color: #464646;
      opacity: 0.5; }
  .price-item__more {
    display: block;
    margin-bottom: 14px;
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 1;
    letter-spacing: 0.2em;
    color: #010101;
    text-transform: uppercase; }
  .price-item .button {
    width: 100%;
    max-width: 220px; }

/*------------------------------------*\
  #SERVICES
\*------------------------------------*/
.services {
  position: relative;
  padding-bottom: 80px; }
  @media (min-width: 62em) {
    .services {
      padding-bottom: 90px; } }
  @media (min-width: 80em) {
    .services {
      padding-bottom: 100px; } }
  @media (min-width: 100em) {
    .services {
      padding-bottom: 115px; } }
  @media (min-width: 1280px) {
    .services__container.container {
      max-width: 1590px;
      padding-left: 130px;
      padding-right: 100px; } }
  .services__title {
    margin-bottom: 36px; }
    @media (min-width: 62em) {
      .services__title {
        margin-top: 24px; } }
    @media (min-width: 80em) {
      .services__title {
        margin-top: 40px; } }
    @media (min-width: 1470px) {
      .services__title {
        margin-top: 60px; } }

/*------------------------------------*\
  #BANNER
\*------------------------------------*/
.banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  max-height: 916px;
  min-height: 570px;
  padding-top: 70px;
  padding-bottom: 70px;
  text-align: center;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover; }
  @media (min-width: 80em) {
    .banner {
      padding-top: 100px;
      padding-bottom: 80px; } }
  .banner__title.title {
    margin-bottom: 40px;
    color: #bea67c; }
    .banner__title.title:after {
      border-color: #bea67c; }
    @media (min-width: 62em) {
      .banner__title.title {
        margin-bottom: 58px; } }
  .banner__desc {
    max-width: 520px;
    margin: 0 auto 50px auto;
    color: #fff; }
    @media (min-width: 62em) {
      .banner__desc {
        margin-bottom: 65px; } }
  .banner .button {
    width: 305px; }

/*------------------------------------*\
  #OFFERS-N-CONTACT
\*------------------------------------*/
.offers-n-contact {
  padding-top: 70px;
  padding-bottom: 64px; }
  @media (min-width: 62em) {
    .offers-n-contact {
      padding-top: 80px;
      padding-bottom: 80px; } }
  @media (min-width: 80em) {
    .offers-n-contact {
      padding-top: 120px;
      padding-bottom: 120px; } }
  @media (min-width: 100em) {
    .offers-n-contact {
      padding-top: 164px;
      padding-bottom: 185px; } }

.offers {
  position: relative;
  padding-bottom: 60px;
  margin-bottom: auto; }
  @media (min-width: 62em) {
    .offers {
      padding-top: 50px; } }
  @media (min-width: 80em) {
    .offers {
      padding-top: 70px;
      padding-bottom: 250px; } }
  @media (min-width: 100em) {
    .offers {
      padding-top: 90px; } }
  .offers__unit {
    position: relative;
    display: flex;
    flex-direction: column; }
  .offers__title {
    margin-bottom: 24px; }
    @media (min-width: 62em) {
      .offers__title {
        position: relative;
        left: -70px;
        white-space: nowrap; } }
  .offers__desc.inner-block {
    position: relative; }
    @media (min-width: 48em) {
      .offers__desc.inner-block {
        padding-left: 9.375vw;
        padding-right: 7vw; } }
    @media (min-width: 62em) {
      .offers__desc.inner-block {
        padding-left: 5.83vw; } }
  .offers-img-1 {
    margin-bottom: auto;
    display: none; }
    @media (min-width: 62em) {
      .offers-img-1 {
        display: block; } }
  .offers-img-2 {
    text-align: center; }
    @media (min-width: 100em) {
      .offers-img-2 {
        position: absolute;
        top: 0;
        left: 100%;
        transform: translateX(-70px);
        width: 100%;
        max-width: 427px; }
        .offers-img-2:after {
          content: '';
          display: block;
          position: absolute;
          top: 28%;
          left: 0;
          transform: translateX(-66%);
          width: 7.76vw;
          height: 0;
          border: 1px solid #000; } }

/* ==========================================================================
   #CONTACT
   ========================================================================== */
.contact {
  position: relative;
  display: flex;
  flex-direction: column; }
  @media (min-width: 62em) {
    .contact {
      order: -1; } }
  .contact__inner {
    position: relative;
    margin-bottom: 64px; }
  .contact__title {
    margin-bottom: 28px; }
  .contact__address {
    margin-bottom: 24px; }

.contact__map {
  position: relative;
  height: 374px;
  overflow: hidden; }
  .contact__map iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

@media (min-width: 62em) {
  .contact__map--main {
    height: 600px; } }

@media (min-width: 80em) {
  .contact__map--main {
    height: 812px; } }

/* #contact--room
   ========================================================================== */
.contact--room {
  padding-top: 64px;
  padding-bottom: 64px; }
  @media (min-width: 62em) {
    .contact--room {
      padding-bottom: 80px; } }
  @media (min-width: 80em) {
    .contact--room {
      padding-bottom: 100px; } }
  @media (min-width: 100em) {
    .contact--room {
      padding-bottom: 130px; } }

/*------------------------------------*\
  #FEEDBACK
\*------------------------------------*/
.feedback {
  padding-bottom: 60px; }
  @media (min-width: 62em) {
    .feedback {
      padding-bottom: 0; } }
  .feedback__desc.inner-block {
    position: relative;
    padding-right: 0; }
    @media (min-width: 48em) {
      .feedback__desc.inner-block {
        padding-left: 9.375vw; } }
    @media (min-width: 62em) {
      .feedback__desc.inner-block {
        padding-left: 5.83vw; } }
  .feedback__grid {
    align-items: stretch; }
    .feedback__grid [class*=pure-u]:last-child {
      order: -1;
      margin-bottom: 50px; }
      @media (min-width: 62em) {
        .feedback__grid [class*=pure-u]:last-child {
          order: 0;
          margin-bottom: 0;
          padding-top: 60px;
          padding-left: 5.83vw; } }
      @media (min-width: 80em) {
        .feedback__grid [class*=pure-u]:last-child {
          padding-top: 120px; } }
      @media (min-width: 100em) {
        .feedback__grid [class*=pure-u]:last-child {
          padding-top: 162px; } }
  .feedback__title {
    margin-bottom: 50px; }
    @media (min-width: 62em) {
      .feedback__title {
        margin-bottom: 65px; } }
  .feedback__form--main .form-control__wrapper {
    max-width: 317px;
    margin-bottom: 24px; }
    @media (min-width: 62em) {
      .feedback__form--main .form-control__wrapper {
        margin-bottom: 34px; } }
    .feedback__form--main .form-control__wrapper--textarea {
      max-width: 410px; }
  .feedback__form--main .button {
    width: 220px; }

/* #feedback--room
   ========================================================================== */
.feedback--room {
  padding-top: 64px;
  padding-bottom: 64px; }
  @media (min-width: 80em) {
    .feedback--room {
      padding-bottom: 80px; } }
  @media (min-width: 90em) {
    .feedback--room {
      padding-bottom: 100px; } }
  @media (min-width: 100em) {
    .feedback--room {
      padding-bottom: 135px; } }

.feedback__form--room {
  display: flex;
  flex-wrap: wrap; }
  @media (min-width: 62em) {
    .feedback__form--room {
      margin-left: -30px;
      margin-right: -30px; } }

.feedback__form--room .feedback__form-left,
.feedback__form--room .feedback__form-right {
  width: 100%; }
  @media (min-width: 62em) {
    .feedback__form--room .feedback__form-left,
    .feedback__form--room .feedback__form-right {
      padding-left: 30px;
      padding-right: 30px; } }
  .feedback__form--room .feedback__form-left:after,
  .feedback__form--room .feedback__form-right:after {
    content: '';
    display: block;
    clear: both; }

@media (min-width: 62em) {
  .feedback__form--room .feedback__form-left {
    width: 33.333333%; } }

@media (min-width: 62em) {
  .feedback__form--room .feedback__form-right {
    width: 66.666666%; } }

.feedback__form--room .form-control__wrapper {
  margin-bottom: 28px; }

.feedback__form--room .form-textarea {
  height: 172px; }

.feedback__form--room .button {
  display: block;
  width: 228px;
  margin-left: auto;
  margin-right: auto; }
  @media (min-width: 36em) {
    .feedback__form--room .button {
      float: right;
      margin-left: 0;
      margin-right: 0; } }

/* ==========================================================================
   #GALLERY
   ========================================================================== */
.gallery {
  padding-top: 64px;
  padding-bottom: 64px; }
  @media (min-width: 80em) {
    .gallery {
      padding-bottom: 78px; } }

.gallery__list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -4px;
  margin-right: -4px; }
  @media (min-width: 36em) {
    .gallery__list {
      margin-left: -8px;
      margin-right: -8px; } }
  @media (min-width: 48em) {
    .gallery__list {
      margin-left: -15px;
      margin-right: -15px; } }

.gallery__item {
  width: calc(50% - 8px);
  position: relative;
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 8px; }
  .gallery__item:focus {
    outline: none; }
  @media (min-width: 36em) {
    .gallery__item {
      width: calc(50% - 16px);
      margin-left: 8px;
      margin-right: 8px;
      margin-bottom: 16px; } }
  @media (min-width: 48em) {
    .gallery__item {
      width: calc(33.333333% - 30px);
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 30px; } }

.gallery__item:before {
  content: '';
  float: left;
  padding-top: 100%; }

.gallery__item:after {
  content: '';
  display: block;
  clear: both; }

.gallery__item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; }

.button.button--gallery-more {
  display: block;
  width: 228px;
  margin-top: 64px;
  margin-left: auto;
  margin-right: auto; }

/* ==========================================================================
   #CALENDAR
   ========================================================================== */
.ui-datepicker {
  padding-top: 0;
  width: 17em; }
  @media (min-width: 22em) {
    .ui-datepicker {
      width: 20em; } }

.ui-datepicker .ui-datepicker-header {
  margin-left: -0.2em;
  margin-right: -0.2em;
  margin-bottom: 4px;
  padding: 10px 0;
  text-transform: uppercase;
  font-size: 0.875rem; }

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  top: 0;
  width: 24px;
  height: 100%;
  background-color: #bea67c;
  border: 0 !important;
  border-radius: 0; }

.ui-datepicker .ui-datepicker-prev-hover,
.ui-datepicker .ui-datepicker-next-hover {
  top: 0; }

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-prev-hover {
  left: 0; }

.ui-datepicker .ui-datepicker-next,
.ui-datepicker .ui-datepicker-next-hover {
  right: 0; }

.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
  width: 12px;
  height: 12px;
  margin: 0;
  border: 1px solid transparent;
  border-left-color: #fff;
  border-top-color: #fff;
  background: none; }

.ui-datepicker .ui-datepicker-prev span {
  transform: translate(-30%, -50%) rotate(-45deg); }

.ui-datepicker .ui-datepicker-next span {
  transform: translate(-80%, -50%) rotate(135deg); }

.ui-datepicker table {
  font-size: 0.8rem; }

.ui-datepicker th {
  text-transform: uppercase;
  font-size: 0.65625rem;
  text-align: center; }

.ui-datepicker td {
  text-align: center; }

.ui-datepicker td span,
.ui-datepicker td a {
  text-align: center;
  border: 0 !important;
  padding-top: 0.3em;
  padding-bottom: 0.3em; }

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  background-color: #f6f6f6;
  color: inherit; }

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
  background-color: #bea67c;
  color: #fff; }

.calendar .ui-datepicker-inline {
  width: 100% !important;
  transform: translateX(0); }

.calendar .ui-datepicker-inline.ui-widget.ui-widget-content {
  border: 0; }

.calendar .ui-datepicker-inline.ui-datepicker-multi-2 .ui-datepicker-group {
  width: 46%; }
  @media (min-width: 80em) {
    .calendar .ui-datepicker-inline.ui-datepicker-multi-2 .ui-datepicker-group {
      width: 44%; } }

.calendar .ui-datepicker-inline.ui-datepicker-multi-2 .ui-datepicker-group-first {
  margin-right: 8%; }
  @media (min-width: 80em) {
    .calendar .ui-datepicker-inline.ui-datepicker-multi-2 .ui-datepicker-group-first {
      margin-right: 12%; } }

.calendar .ui-datepicker-inline .ui-datepicker-header {
  margin-bottom: 0;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  border: 0;
  background: none;
  color: #bea67c;
  font-weight: bold;
  font-family: "Proxima Nova", sans-serif;
  font-size: 0.875rem;
  line-height: 1.2;
  letter-spacing: 0.21em;
  text-transform: uppercase; }

.calendar .ui-datepicker-inline table {
  width: 100%;
  margin: 0; }

.calendar .ui-datepicker-inline th,
.calendar .ui-datepicker-inline td {
  font-size: 1.125rem; }

.calendar .ui-datepicker-inline td span,
.calendar .ui-datepicker-inline td a {
  display: block;
  padding: .5em .2em;
  text-align: center;
  text-decoration: none; }

.calendar .ui-datepicker-inline .ui-state-default {
  border: 0;
  background: #fff;
  font-weight: normal;
  font-size: 1.125rem;
  color: #010101; }

.calendar .ui-datepicker-inline .ui-state-highlight,
.calendar .ui-datepicker-inline .ui-widget-content .ui-state-highlight,
.calendar .ui-datepicker-inline .ui-widget-header .ui-state-highlight {
  border: 0;
  background: #fff;
  color: #010101; }

.calendar .ui-datepicker-inline .ui-datepicker-prev,
.calendar .ui-datepicker-inline .ui-datepicker-next {
  top: 50%;
  width: 44px;
  height: 30px;
  transform: translate(0%, -50%);
  border: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 34px 11px; }
  .calendar .ui-datepicker-inline .ui-datepicker-prev:hover, .calendar .ui-datepicker-inline .ui-datepicker-prev:focus, .calendar .ui-datepicker-inline .ui-datepicker-prev:active,
  .calendar .ui-datepicker-inline .ui-datepicker-next:hover,
  .calendar .ui-datepicker-inline .ui-datepicker-next:focus,
  .calendar .ui-datepicker-inline .ui-datepicker-next:active {
    background-color: #fff; }

.calendar .ui-datepicker-inline .ui-datepicker-next {
  background-image: url(../img/icons/golden-arrow-right.png); }

.calendar .ui-datepicker-inline .ui-datepicker-prev {
  background-image: url(../img/icons/golden-arrow-left.png); }

.calendar .ui-datepicker-inline .ui-datepicker-prev span,
.calendar .ui-datepicker-inline .ui-datepicker-next span {
  display: none; }

.calendar .ui-datepicker-inline .ui-datepicker-week-end,
.calendar .ui-datepicker-inline .ui-datepicker-week-end .ui-state-default {
  color: #bea67c; }

.calendar .ui-datepicker-inline td.is-available {
  opacity: 0.3; }

.calendar-wrapper {
  position: relative;
  width: 100%;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto; }
  @media (min-width: 48em) {
    .calendar-wrapper {
      max-width: 870px; } }

.calendar-wrapper .button {
  display: block;
  width: 228px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 48px; }
  @media (min-width: 80em) {
    .calendar-wrapper .button {
      margin-top: 84px; } }

.calendar__heading {
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  color: #C4C4C4;
  z-index: 1; }
  @media (min-width: 62em) {
    .calendar__heading {
      position: absolute;
      top: 9px;
      left: 50%;
      transform: translateX(-50%);
      margin-bottom: 0; } }

/* ==========================================================================
   #BOOKING
   ========================================================================== */
.booking {
  padding-top: 64px;
  padding-bottom: 64px; }
  @media (min-width: 80em) {
    .booking {
      padding-bottom: 78px; } }

/*------------------------------------*\
  #FOOTER
\*------------------------------------*/
.footer {
  padding-top: 16px;
  padding-bottom: 30px;
  color: #464646;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover; }
  .footer__row {
    display: flex;
    flex-wrap: wrap; }
  .footer__module {
    margin-right: 64px; }
    .footer__module:last-child {
      margin-right: 0; }
  .footer__address {
    padding-top: 20px;
    font-size: 0.875rem;
    margin-bottom: 8px; }
  .footer__rights {
    font-size: 0.625rem; }

/*------------------------------------------*/
/*  #CONTAINER
/*------------------------------------------*/
.container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto; }

@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1280px) {
  .container {
    max-width: 1470px;
    padding-left: 140px;
    padding-right: 140px; }
  .container--wider {
    max-width: 1610px;
    padding-left: 110px;
    padding-right: 110px; } }

.container--fluid {
  width: 100%; }

/*------------------------------------*\
  #BUTTON
\*------------------------------------*/
.button {
  position: relative;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: top;
  user-select: none;
  border: 2px solid #bea67c;
  padding: 19px 16px;
  font-family: "Proxima Nova", sans-serif;
  font-size: 0.875rem;
  line-height: 1;
  letter-spacing: 0.2em;
  font-weight: bold;
  border-radius: 0;
  color: #bea67c;
  text-transform: uppercase;
  background-color: transparent;
  transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
  cursor: pointer; }
  .button:hover {
    background-color: #bea67c;
    color: #fff; }
  .button:focus {
    outline: none; }
  .button--light {
    border-color: #fff;
    color: #fff; }
    .button--light:hover {
      background-color: #fff;
      color: #010101; }
  .button--arrow .arrow {
    width: 34px;
    height: 11px;
    margin-left: 8px;
    stroke: #bea67c;
    transition: stroke 0.25s ease-in-out; }
  .button--arrow:hover .arrow {
    stroke: #fff; }
  .button--arrow.button--light .arrow {
    stroke: #fff; }
  .button--arrow.button--light:hover .arrow {
    stroke: #010101; }
  .button--video {
    padding-top: 13px;
    padding-bottom: 13px;
    line-height: 1.8; }
    .button--video::before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 25px;
      height: 25px;
      margin-right: 15px;
      background-position: -149px -1px;
      background-image: url(../img/icons/icons-spritesheet.png);
      background-repeat: no-repeat;
      background-size: 188px 104px; }
    .button--video:hover {
      background-color: transparent;
      color: #fff; }
  .button--panorama {
    padding-top: 13px;
    padding-bottom: 13px;
    line-height: 1.8; }
    .button--panorama::before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 32px;
      height: 32px;
      margin-right: 13px;
      background-image: url(../img/icons/panorama.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover; }
    .button--panorama:hover {
      background-color: transparent;
      color: #fff; }

.button-to-top {
  display: none;
  position: fixed;
  right: 1rem;
  bottom: 8rem;
  width: 44px;
  height: 44px;
  padding: 0;
  border: 0;
  border-radius: 50%;
  box-shadow: 0;
  background-color: #bea67c;
  cursor: pointer;
  transition: all .4s; }
  .button-to-top:hover, .button-to-top:focus {
    outline: none;
    opacity: 0.75; }

.button-to-top::after {
  content: '';
  position: relative;
  top: 2px;
  width: 10px;
  height: 10px;
  transform: rotate(-45deg);
  border: 2px solid transparent;
  border-top-color: #fff;
  border-right-color: #fff; }

.button-to-top.on {
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center; }

.form--modal-booking [type=submit] {
  width: 100%;
  max-width: 318px; }

/*------------------------------------*\
  #ICON
\*------------------------------------*/
.icon {
  position: relative;
  display: inline-block; }
  .icon::after {
    background-image: url(../img/icons/icons-spritesheet.png);
    background-repeat: no-repeat;
    background-size: 188px 104px; }

.icon-arrow-lg-right::after {
  content: '';
  display: block;
  width: 34px;
  height: 11px;
  background-position: -1px -1px; }

.icon-calendar::after {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  background-image: url(../img/icons/calendar.png);
  background-size: contain; }

.icon-mouse::after {
  content: '';
  display: block;
  width: 20px;
  height: 30px;
  background-position: -59px -1px; }

.icon-parking::after {
  content: '';
  display: block;
  width: 70px;
  height: 59px;
  background-position: -1px -33px; }

.icon-plate::after {
  content: '';
  display: block;
  width: 66px;
  height: 46px;
  background-position: -81px -1px; }

.icon-play::after {
  content: '';
  display: block;
  width: 25px;
  height: 25px;
  background-position: -149px -1px; }

.icon-tv::after {
  content: '';
  display: block;
  width: 57px;
  height: 54px;
  background-position: -73px -49px; }

.icon-wifi::after {
  content: '';
  display: block;
  width: 55px;
  height: 44px;
  background-position: -132px -49px; }

.icon-svg {
  display: inline-block; }

.icon-svg-wifi {
  width: 55px;
  height: 55px; }

.icon-svg-television {
  width: 58px;
  height: 58px; }

.icon-svg-bathtub {
  width: 61px;
  height: 61px; }

.icon-svg-bathrobe {
  width: 55px;
  height: 55px; }

/*------------------------------------*\
  #BRAND
\*------------------------------------*/
.brand {
  display: block;
  outline: 0;
  text-decoration: none; }
  .brand--hero {
    width: 190px;
    margin-bottom: 45px; }
    @media (min-width: 48em) {
      .brand--hero {
        width: 28.8vw; } }
    @media (min-width: 100em) {
      .brand--hero {
        margin-bottom: 70px; } }
  .brand--footer {
    width: 190px;
    height: 71px; }

/*------------------------------------*\
  #MENU
\*------------------------------------*/
.menu__link {
  display: block;
  font-weight: bold;
  font-size: 0.9375rem;
  text-transform: uppercase;
  transition: color 0.25s ease-out; }

.menu--main {
  display: none; }
  @media (min-width: 1340px) {
    .menu--main {
      display: flex;
      flex-wrap: wrap; } }
  .menu--main .menu__link {
    margin-left: 38px;
    line-height: 1.15;
    white-space: nowrap;
    color: rgba(255, 255, 255, 0.5); }
    .menu--main .menu__link:first-child {
      margin-left: 0; }
      @media (min-width: 1400px) {
        .menu--main .menu__link:first-child {
          margin-left: 38px; } }
    .menu--main .menu__link:hover {
      color: white; }
    .menu--main .menu__link:focus {
      outline: 0;
      color: rgba(255, 255, 255, 0.8); }

.menu--mobile {
  max-width: 320px; }
  .menu--mobile .menu__link {
    margin-bottom: 12px; }
    .menu--mobile .menu__link:last-child {
      margin-bottom: 0; }

.mobile-nav {
  visibility: hidden; }
  .mobile-nav__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0%;
    opacity: 0;
    background: rgba(255, 255, 255, 0.95);
    visibility: hidden;
    transition: opacity .35s, visibility .35s, height .35s;
    overflow: hidden;
    z-index: 999; }
  .mobile-nav__content {
    position: relative;
    padding-top: 100px;
    width: 100%;
    overflow-y: auto;
    z-index: 1000; }
  .mobile-nav__container > *:not(:last-child) {
    position: relative;
    margin-bottom: 24px;
    padding-bottom: 24px; }
    .mobile-nav__container > *:not(:last-child):after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 150px;
      height: 0;
      border: 1px solid #bea67c; }

.mobile-nav.is-active {
  visibility: visible;
  z-index: 999; }
  .mobile-nav.is-active .mobile-nav__overlay {
    visibility: visible;
    opacity: 1;
    height: 100%; }

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 4px 0px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.6; }
  .hamburger.is-active:hover {
    opacity: 0.6; }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #bea67c; }

.hamburger-box {
  width: 28px;
  height: 19px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 28px;
    height: 3px;
    background-color: #bea67c;
    border-radius: 0px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -8px; }
  .hamburger-inner::after {
    bottom: -8px; }

/*
   * Spring
   */
.hamburger--spring .hamburger-inner {
  top: 1.5px;
  transition: background-color 0s 0.13s linear; }
  .hamburger--spring .hamburger-inner::before {
    top: 8px;
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spring .hamburger-inner::after {
    top: 16px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent !important; }
  .hamburger--spring.is-active .hamburger-inner::before {
    top: 0;
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 8px, 0) rotate(45deg); }
  .hamburger--spring.is-active .hamburger-inner::after {
    top: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 8px, 0) rotate(-45deg); }

.hamburger {
  position: fixed;
  top: 36px;
  left: 30px;
  line-height: 1;
  outline: none;
  cursor: pointer;
  z-index: 99999; }
  @media (min-width: 1340px) {
    .hamburger {
      display: none; } }
  .hamburger--scroll {
    top: 28px; }
    .hamburger--scroll::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -55%);
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.9);
      z-index: -1; }

/*------------------------------------*\
  #TITLE
\*------------------------------------*/
.title {
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: 0.07em;
  color: #010101;
  text-transform: uppercase; }
  .title {
    font-size: 28px; }
    @media screen and (min-width: 320px) {
      .title {
        font-size: calc(28px + 20 * ((100vw - 320px) / 1600)); } }
    @media screen and (min-width: 1920px) {
      .title {
        font-size: 48px; } }
  .title--dash {
    position: relative; }
    .title--dash:after {
      content: '';
      flex: none;
      display: block;
      width: 7.76vw;
      max-width: 150px;
      height: 0;
      border: 1px solid #000; }
  .title--dash-left {
    display: flex;
    align-items: center; }
    .title--dash-left:after {
      order: -1;
      margin-right: 30px; }
  .title--dash-bottom {
    padding-bottom: 26px;
    text-align: center; }
    .title--dash-bottom:after {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%); }
  .title--offers {
    font-size: 23px; }
    @media screen and (min-width: 320px) {
      .title--offers {
        font-size: calc(23px + 25 * ((100vw - 320px) / 1600)); } }
    @media screen and (min-width: 1920px) {
      .title--offers {
        font-size: 48px; } }

.room-page .title {
  font-size: 28px; }
  @media screen and (min-width: 320px) {
    .room-page .title {
      font-size: calc(28px + 18 * ((100vw - 320px) / 1600)); } }
  @media screen and (min-width: 1920px) {
    .room-page .title {
      font-size: 46px; } }

.room-page .title--dash-bottom {
  margin-bottom: 1.95em; }

.modal--booking .title--dash {
  margin-bottom: 3.5rem; }
  @media (min-width: 100em) {
    .modal--booking .title--dash {
      margin-bottom: 7.25rem; } }

.modal--booking .title--dash:after {
  border-color: #fff; }

/*------------------------------------*\
  #DESC
\*------------------------------------*/
@media (min-width: 48em) {
  .inner-block {
    padding-left: 9.375vw;
    padding-right: 2.6vw; } }

.inner-block > *:not(h2):not(h3):not(form) {
  margin-bottom: 26px; }

.inner-block h3 {
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.4;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #010101; }

/*------------------------------------*\
  #FORM
\*------------------------------------*/
.form-row {
  display: flex; }

.form-row--align-end {
  align-items: flex-end; }

.form-row--hero {
  align-items: center;
  margin-bottom: 16px; }
  .form-row--hero:last-child {
    margin-bottom: 0; }
  .form-row--hero .form-control__wrapper {
    margin-bottom: 0; }

.form-control__wrapper {
  width: 100%; }

.form-label {
  display: block;
  margin-bottom: 12px;
  padding-left: 26px;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1;
  letter-spacing: 0.2em;
  color: #010101;
  text-transform: uppercase; }

.form-input,
.form-textarea {
  display: block;
  width: 100%;
  border: 1px solid #dddddd;
  font-size: inherit;
  color: inherit;
  background: transparent; }
  .form-input:focus,
  .form-textarea:focus {
    outline: 2px solid #bea67c;
    outline-offset: -1px; }
  .form-input.error,
  .form-textarea.error {
    border-color: red; }

.form-input {
  height: 56px;
  padding-left: 26px;
  padding-right: 16px;
  line-height: 56px; }

.form-input--date {
  position: relative;
  background-image: url(../img/icons/calendar.png);
  background-repeat: no-repeat;
  background-position: top 50% right 20px;
  background-size: 20px 20px; }

.form-textarea {
  height: 150px;
  padding: 18px 16px 18px 26px;
  line-height: 1.15; }

.form-inc__wrap {
  display: table;
  width: 100%; }

.form-inc__label {
  display: table-cell;
  width: 100%;
  vertical-align: middle;
  font-weight: bold; }

.form-inc__label-text {
  display: table;
  margin-right: 12px;
  line-height: 1em; }

.form-inc__controls {
  display: table;
  width: 120px; }

.form-inc__controls-left,
.form-inc__controls-center,
.form-inc__controls-right {
  display: table-cell;
  vertical-align: middle;
  line-height: 1em; }

.form-inc__controls-left {
  text-align: left; }

.form-inc__controls-center {
  text-align: center; }

.form-inc__controls-right {
  text-align: right; }

.form-inc__btn {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 36px;
  height: 36px;
  padding: 0;
  border: 0;
  cursor: pointer;
  text-align: center;
  line-height: 1;
  background-color: #bea67c;
  box-shadow: none;
  touch-action: manipulation; }
  .form-inc__btn:focus {
    outline: none;
    opacity: 0.9;
    transition: box-shadow 0.25s ease-out; }

.form-inc__btn-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000;
  font-size: 16px; }

.form-control__wrapper,
.form__acceptance-wrapper,
.form-inc__controls {
  position: relative; }
  .form-control__wrapper label.error,
  .form__acceptance-wrapper label.error,
  .form-inc__controls label.error {
    position: absolute;
    top: 102%;
    left: 0;
    font-size: 13px;
    color: red; }

.form-inc__controls label.error {
  right: 0;
  left: auto;
  white-space: nowrap; }

.form__acceptance-mask {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 1px solid #000; }

input:checked ~ label .form__acceptance-mask:after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 12px;
  height: 6px;
  border: 1px solid transparent;
  border-left-color: #000;
  border-bottom-color: #000; }

/* #form--modal-booking
   ========================================================================== */
.form--modal-booking {
  color: #fff; }

.form--modal-booking .form-row {
  flex-wrap: wrap;
  justify-content: space-between; }

.form--modal-booking .form-control__wrapper,
.form--modal-booking .form-inc__wrap {
  margin-bottom: 2.5rem; }
  @media (min-width: 100em) {
    .form--modal-booking .form-control__wrapper,
    .form--modal-booking .form-inc__wrap {
      margin-bottom: 3.5rem; } }

.form--modal-booking .form-label {
  margin-bottom: 16px;
  padding-left: 26px;
  color: #fff;
  text-align: left; }

.form--modal-booking .form-row {
  margin-left: -24px;
  margin-right: -24px; }

.form--modal-booking .form-row__col {
  padding-left: 24px;
  padding-right: 24px; }

.form--modal-booking input,
.form--modal-booking textarea {
  color: #fff; }

.form--modal-booking input::placeholder,
.form--modal-booking textarea::placeholder {
  color: #b9b8c1; }

.form--modal-booking .form-inc__wrap {
  color: #fff;
  font-size: 1rem; }

.form--modal-booking .form-inc__label {
  padding-left: 26px; }

.form--modal-booking .form__acceptance-wrapper {
  margin-bottom: 4rem; }
  @media (min-width: 100em) {
    .form--modal-booking .form__acceptance-wrapper {
      margin-bottom: 6rem; } }

.form--modal-booking .form__acceptance-label {
  display: flex;
  align-items: flex-start;
  width: 100%;
  max-width: 580px; }

.form--modal-booking .form__acceptance-mask {
  flex: none;
  margin-right: 26px;
  border-color: #fff; }

.form--modal-booking input:checked ~ label .form__acceptance-mask:after {
  border-left-color: #fff;
  border-bottom-color: #fff; }

/* ==========================================================================
   #BACK-TEXT
   ========================================================================== */
.back-text {
  font-weight: bold;
  line-height: 1.15;
  letter-spacing: 0.07em;
  color: rgba(208, 208, 208, 0.2);
  text-transform: uppercase;
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1; }
  .back-text {
    font-size: 100px; }
    @media screen and (min-width: 320px) {
      .back-text {
        font-size: calc(100px + 168 * ((100vw - 320px) / 1600)); } }
    @media screen and (min-width: 1920px) {
      .back-text {
        font-size: 268px; } }

.back-text--about {
  left: -20px; }
  @media (min-width: 48em) {
    .back-text--about {
      left: -44px; } }

.back-text--price {
  transform: translateY(-38%); }
  @media (min-width: 48em) {
    .back-text--price {
      left: 30%; } }
  @media (min-width: 100em) {
    .back-text--price {
      left: 46%; } }

.back-text--services {
  transform: translateY(-18%); }

.back-text--offers {
  transform: translate(-4%, -40%); }
  @media (min-width: 62em) {
    .back-text--offers {
      transform: translate(-2%, -10%); } }

.back-text--contact {
  transform: translate(-5%, -40%); }
  @media (min-width: 120em) {
    .back-text--contact {
      transform: translate(-24%, -40%); } }

/*------------------------------------*\
  #CONTENT-IMG
\*------------------------------------*/
.content-img {
  text-align: center; }
  @media (max-width: 61.99em) {
    .content-img {
      position: relative;
      height: 0;
      width: 100%;
      padding-top: 56.25%; }
      .content-img img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover; } }

/*------------------------------------*\
  #MODAL
\*------------------------------------*/
.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.93);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.modal__container {
  position: relative;
  background-color: #fff;
  padding: 30px;
  width: 100%;
  max-width: 320px;
  max-height: 100vh;
  border-radius: 4px;
  overflow-y: auto; }
  @media (min-width: 992px) {
    .modal__container {
      max-width: 380px; } }

.modal__close {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  padding: 12px;
  line-height: 1;
  background: transparent;
  border: 0; }
  .modal__close:focus {
    outline: none; }

.modal__close:before {
  content: "\2715"; }

.modal__content {
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.8); }

.answer-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center; }

.answer-text {
  font-size: 1.2rem;
  text-align: center; }

@-webkit-keyframes mmfadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes mmfadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes mmfadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes mmfadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes mmslideIn {
  from {
    -webkit-transform: translateY(15%);
    transform: translateY(15%); }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes mmslideIn {
  from {
    -webkit-transform: translateY(15%);
    transform: translateY(15%); }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes mmslideOut {
  from {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  to {
    -webkit-transform: translateY(-10%);
    transform: translateY(-10%); } }

@keyframes mmslideOut {
  from {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  to {
    -webkit-transform: translateY(-10%);
    transform: translateY(-10%); } }

.modal {
  display: none; }

.modal.is-open {
  display: block; }
  .modal.is-open .modal__overlay {
    z-index: 555; }

.modal[aria-hidden="false"] .modal__overlay {
  -webkit-animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1); }

.modal[aria-hidden="false"] .modal__container {
  -webkit-animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
  animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1); }

.modal[aria-hidden="true"] .modal__overlay {
  -webkit-animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
  animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1); }

.modal[aria-hidden="true"] .modal__container {
  -webkit-animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
  animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1); }

.modal .modal__container,
.modal .modal__overlay {
  will-change: transform; }

/* #modal--booking
   ========================================================================== */
.modal--booking .modal__container {
  background-color: transparent;
  max-width: none; }

.modal--booking .modal__close {
  top: 2rem;
  right: 0.5rem;
  color: #fff;
  font-size: 1.5rem; }
  @media (min-width: 62em) {
    .modal--booking .modal__close {
      font-size: 1.75rem;
      right: 1rem; } }

.modal--booking .modal__content {
  margin-top: 4rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 400px; }
  @media (min-width: 62em) {
    .modal--booking .modal__content {
      max-width: 690px; } }

.modal-booking__total {
  color: #fff;
  font-size: 1rem;
  margin-bottom: 2.5rem; }
  @media (min-width: 62em) {
    .modal-booking__total {
      margin-bottom: 0; } }

.modal-booking__price {
  margin-bottom: 4px;
  line-height: 1.15;
  letter-spacing: 0.1em; }

.modal-booking__price--bigger {
  letter-spacing: 0.075em; }
  .modal-booking__price--bigger {
    font-size: 24px; }
    @media screen and (min-width: 320px) {
      .modal-booking__price--bigger {
        font-size: calc(24px + 12 * ((100vw - 320px) / 1600)); } }
    @media screen and (min-width: 1920px) {
      .modal-booking__price--bigger {
        font-size: 36px; } }
