// Colors
$black: #010101;
$grey-l: #464646;
$brand: #bea67c;

// Fonts
$main-font: 'Proxima Nova', sans-serif;
$second-font: 'PT Sans', sans-serif;


$min_width: 320px;
$max_width: 1920px;
