/*------------------------------------------*/
/*  #CONTAINER
/*------------------------------------------*/

.container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

@media (min-width: 576px) {
  .container { max-width: 540px; }
}

@media (min-width: 768px) {
  .container { max-width: 720px; }
}

@media (min-width: 992px) {
  .container { max-width: 960px; }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1470px;
    padding-left: 140px;
    padding-right: 140px;
  }

  .container--wider {
    max-width: 1610px;
    padding-left: 110px;
    padding-right: 110px;
  }
}

.container--fluid {
  width: 100%;
}