/*------------------------------------*\
  #FEEDBACK
\*------------------------------------*/

.feedback {
  padding-bottom: 60px;

  @media (min-width: 62em) {
    padding-bottom: 0;
  }


  &__desc.inner-block {
    position: relative;
    padding-right: 0;

    @media (min-width: 48em) {
      padding-left: 9.375vw;
    }

    @media (min-width: 62em) {
      padding-left: 5.83vw;
    }
  }

  &__grid {
    align-items: stretch;

    [class*=pure-u]:last-child {
      order: -1;
      margin-bottom: 50px;

      @media (min-width: 62em) {
        order: 0;
        margin-bottom: 0;
        padding-top: 60px;
        padding-left: 5.83vw;
      }

      @media (min-width: 80em) {
        padding-top: 120px;
      }

      @media (min-width: 100em) {
        padding-top: 162px;
      }
    }
  }


  &__title {
    margin-bottom: 50px;

    @media (min-width: 62em) {
      margin-bottom: 65px;
    }
  }

  &__form--main {

    .form-control__wrapper {
      max-width: 317px;
      margin-bottom: 24px;

      @media (min-width: 62em) {
        margin-bottom: 34px;
      }

      &--textarea {
        max-width: 410px;
      }
    }

    .button {
      width: 220px;
    }
  }
}



/* #feedback--room
   ========================================================================== */

.feedback--room {
  padding-top: 64px;
  padding-bottom: 64px;

  @media (min-width: 80em) {
    padding-bottom: 80px;
  }
  @media (min-width: 90em) {
    padding-bottom: 100px;
  }
  @media (min-width: 100em) {
    padding-bottom: 135px;
  }
}


.feedback__form--room {
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 62em) {
    margin-left: -30px;
    margin-right: -30px;
  }
}


.feedback__form--room .feedback__form-left,
.feedback__form--room .feedback__form-right {
  width: 100%;

  @media (min-width: 62em) {
    padding-left: 30px;
    padding-right: 30px;
  }


  &:after {
    content: '';
    display: block;
    clear: both;
  }
}


.feedback__form--room .feedback__form-left {
  

  @media (min-width: 62em) {
    width: 33.333333%;
  }
}


.feedback__form--room .feedback__form-right {


  @media (min-width: 62em) {
    width: 66.666666%;
  }
}


.feedback__form--room .form-control__wrapper {
  margin-bottom: 28px;
}


.feedback__form--room .form-textarea {
  height: 172px;
}


.feedback__form--room .button {
  display: block;
  width: 228px;
  margin-left: auto;
  margin-right: auto;
  

  @media (min-width: 36em) {
    float: right;
    margin-left: 0;
    margin-right: 0;
  }
}