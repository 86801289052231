/*------------------------------------*\
  #MENU
\*------------------------------------*/

.menu {

  &__link {
    display: block;
    font-weight: bold;
    font-size: rem(15);
    text-transform: uppercase;
    transition: color 0.25s ease-out;
  }

  &--main {
    display: none;

    @media (min-width: 1340px) {
      display: flex;
      flex-wrap: wrap;
    }

    .menu__link {
      margin-left: 38px;
      line-height: 1.15;
      white-space: nowrap;
      color: rgba(255, 255, 255, 0.5);

      &:first-child {
        margin-left: 0;

        @media (min-width: 1400px) {
          margin-left: 38px;
        }
      }

      &:hover {
        color: rgba(255, 255, 255, 1);
      }
      &:focus {
        outline: 0;
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }

  &--mobile {
    max-width: 320px;
    
    .menu__link {
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.mobile-nav {
  visibility: hidden;

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0%;
    opacity: 0;
    background: rgba(255, 255, 255, .95);
    visibility: hidden;
    transition:
      opacity .35s,
      visibility .35s,
      height .35s;
    overflow: hidden;
    z-index: 999;
  }

  &__content {
    position: relative;
    padding-top: 100px;
    width: 100%;
    overflow-y: auto;
    z-index: 1000;
  }

  &__container {
    
    > *:not(:last-child) {
      position: relative;
      margin-bottom: 24px;
      padding-bottom: 24px;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 150px;
        height: 0;
        border: 1px solid $brand;
      }
    }
  }
}

.mobile-nav.is-active {
  visibility: visible;
  z-index: 999;
  

  .mobile-nav__overlay {
    visibility: visible;
    opacity: 1;
    height: 100%;
  }
}
