/*------------------------------------*\
  #BANNER
\*------------------------------------*/

.banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  max-height: 916px;
  min-height: 570px;
  // padding-top: 300px;
  // padding-bottom: 250px;
  padding-top: 70px;
  padding-bottom: 70px;
  text-align: center;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;

  @media (min-width: 80em) {
    padding-top: 100px;
    padding-bottom: 80px;
  }

  &__title.title {
    margin-bottom: 40px;
    color: $brand;

    &:after {
      border-color: $brand;
    }

    @media (min-width: 62em) {
      margin-bottom: 58px;
    }
  }

  &__desc {
    max-width: 520px;
    margin: 0 auto 50px auto;
    color: #fff;

    @media (min-width: 62em) {
      margin-bottom: 65px;
    }
  }

  .button {
    width: 305px;
  }
}