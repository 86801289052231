/*------------------------------------*\
  #TITLE
\*------------------------------------*/

.title {
  @include fluid-type($min_width, $max_width, 28px, 48px);

  font-weight: bold;
  line-height: 1.2;
  letter-spacing: 0.07em;
  color: $black;
  text-transform: uppercase;

  &--dash {
    position: relative;

    &:after {
      content: '';
      flex: none;
      display: block;
      width: 7.76vw;
      max-width: 150px;
      height: 0;
      border: 1px solid #000;
    }
  }

  &--dash-left {
    display: flex;
    align-items: center;

    &:after {
      order: -1;
      margin-right: 30px;
    }
  }

  &--dash-bottom {
    padding-bottom: 26px;
    text-align: center;
    
    &:after {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &--offers {
    @include fluid-type($min_width, $max_width, 23px, 48px);
  }
}


.room-page .title {
  @include fluid-type($min_width, $max_width, 28px, 46px);
}


.room-page .title--dash-bottom {
  margin-bottom: 1.95em;
}


.modal--booking .title--dash {
  margin-bottom: 3.5rem;

  @media (min-width: 100em) {
    margin-bottom: 7.25rem;
  }
}


.modal--booking .title--dash:after {
  border-color: #fff;
}