/* ==========================================================================
   #BOOKING
   ========================================================================== */

.booking {
  padding-top: 64px;
  padding-bottom: 64px;


  @media (min-width: 80em) {
    padding-bottom: 78px;
  }
}
