/*------------------------------------*\
  #BUTTON
\*------------------------------------*/

.button {
  position: relative;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: top;
  user-select: none;
  border: 2px solid $brand;
  padding: 19px 16px;
  font-family: $main-font;
  font-size: rem(14);
  line-height: 1;
  letter-spacing: 0.2em;
  font-weight: bold;
  border-radius: 0;
  color: $brand;
  text-transform: uppercase;
  background-color: transparent;
  transition:
    background-color 0.25s ease-in-out,
    color 0.25s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: $brand;
    color: #fff;
  }

  &:focus {
    outline: none;
  }

  &--light {
    border-color: #fff;
    color: #fff;

    &:hover {
      background-color: #fff;
      color: $black;
    }
  }

  &--arrow {

    .arrow {
      width: 34px;
      height: 11px;
      margin-left: 8px;
      stroke: $brand;
      transition: stroke 0.25s ease-in-out;
    }

    &:hover {

      .arrow {
        stroke: #fff;
      }
    }

    &.button--light {

      .arrow {
        stroke: #fff;
      }

      &:hover {

        .arrow {
          stroke: $black;
        }
      }
    }
  }

  &--video {
    padding-top: 13px;
    padding-bottom: 13px;
    line-height: 1.8;

    &::before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 25px;
      height: 25px;
      margin-right: 15px;
      background-position: -149px -1px;
      background-image: url(../img/icons/icons-spritesheet.png);
      background-repeat: no-repeat;
      background-size: 188px 104px;
    }

    &:hover {
      background-color: transparent;
      color: #fff;
    }
  }


  &--panorama {
    padding-top: 13px;
    padding-bottom: 13px;
    line-height: 1.8;

    &::before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 32px;
      height: 32px;
      margin-right: 13px;
      background-image: url(../img/icons/panorama.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    &:hover {
      background-color: transparent;
      color: #fff;
    }
  }
}


.button-to-top {
  display: none;
  position: fixed;
  right: 1rem;
  bottom: 8rem;
  width: 44px;
  height: 44px;
  padding: 0;
  border: 0;
  border-radius: 50%;
  box-shadow: 0;
  background-color: $brand;
  cursor: pointer;
  transition: all .4s;

  &:hover,
  &:focus {
    outline: none;
    opacity: 0.75;
  }
}


.button-to-top::after {
  content: '';
  position: relative;
  top: 2px;
  width: 10px;
  height: 10px;
  transform: rotate(-45deg);
  border: 2px solid transparent;
  border-top-color: #fff;
  border-right-color: #fff;
}


.button-to-top.on {
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form--modal-booking [type=submit] {
  width: 100%;
  max-width: 318px;
}