/*------------------------------------*\
  #ICON
\*------------------------------------*/

.icon {
  position: relative;
  display: inline-block;

  &::after {
    background-image: url(../img/icons/icons-spritesheet.png);
    background-repeat: no-repeat;
    background-size: 188px 104px;
  }
}

.icon-arrow-lg-right::after {
  content: '';
  display: block;
  width: 34px;
  height: 11px;
  background-position: -1px -1px;
}

.icon-calendar::after {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  background-image: url(../img/icons/calendar.png);
  background-size: contain;
  // background-position: -37px -1px;
}

.icon-mouse::after {
  content: '';
  display: block;
  width: 20px;
  height: 30px;
  background-position: -59px -1px;
}

.icon-parking::after {
  content: '';
  display: block;
  width: 70px;
  height: 59px;
  background-position: -1px -33px;
}

.icon-plate::after {
  content: '';
  display: block;
  width: 66px;
  height: 46px;
  background-position: -81px -1px;
}

.icon-play::after {
  content: '';
  display: block;
  width: 25px;
  height: 25px;
  background-position: -149px -1px;
}

.icon-tv::after {
  content: '';
  display: block;
  width: 57px;
  height: 54px;
  background-position: -73px -49px;
}

.icon-wifi::after {
  content: '';
  display: block;
  width: 55px;
  height: 44px;
  background-position: -132px -49px;
}


.icon-svg {
  display: inline-block;
}

.icon-svg-wifi {
  width: 55px;
  height: 55px;
}


.icon-svg-television {
  width: 58px;
  height: 58px;
}


.icon-svg-bathtub {
  width: 61px;
  height: 61px;
}


.icon-svg-bathrobe {
  width: 55px;
  height: 55px;
}


