/* ==========================================================================
   #CALENDAR
   ========================================================================== */

.ui-datepicker {
  padding-top: 0;
  width: 17em;

  @media (min-width: 22em) {
    width: 20em;
  }
}


.ui-datepicker .ui-datepicker-header {
  margin-left: -0.2em;
  margin-right: -0.2em;
  margin-bottom: 4px;
  padding: 10px 0;
  text-transform: uppercase;
  font-size: rem(14);
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  top: 0;
  width: 24px;
  height: 100%;
  background-color: $brand;
  border: 0 !important;
  border-radius: 0;
}


.ui-datepicker .ui-datepicker-prev-hover,
.ui-datepicker .ui-datepicker-next-hover {
  top: 0;
}


.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-prev-hover {
  left: 0;
}


.ui-datepicker .ui-datepicker-next,
.ui-datepicker .ui-datepicker-next-hover {
  right: 0;
}


.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
  width: 12px;
  height: 12px;
  margin: 0;
  border: 1px solid transparent;
  border-left-color: #fff;
  border-top-color: #fff;
  background: none;
}


.ui-datepicker .ui-datepicker-prev span {
  transform: translate(-30%, -50%) rotate(-45deg);
}


.ui-datepicker .ui-datepicker-next span {
  transform: translate(-80%, -50%) rotate(135deg);
}


.ui-datepicker table {
  font-size: 0.8rem;
}


.ui-datepicker th {
  text-transform: uppercase;
  font-size: rem(10.5);
  text-align: center;
}


.ui-datepicker td {
  text-align: center;
}


.ui-datepicker td span,
.ui-datepicker td a {
  text-align: center;
  border: 0 !important;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  background-color: #f6f6f6;
  color: inherit;
}


.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
  background-color: $brand;
  color: #fff;
}


.calendar {

  .ui-datepicker-inline {
    width: 100% !important;
    transform: translateX(0);
  }


  .ui-datepicker-inline.ui-widget.ui-widget-content {
    border: 0;
  }


  .ui-datepicker-inline.ui-datepicker-multi-2 .ui-datepicker-group {
    width: 46%;

    @media (min-width: 80em) {
      width: 44%;
    }
  }


  .ui-datepicker-inline.ui-datepicker-multi-2 .ui-datepicker-group-first {
    margin-right: 8%;

    @media (min-width: 80em) {
      margin-right: 12%;
    }
  }


  .ui-datepicker-inline .ui-datepicker-header {
    margin-bottom: 0;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    border: 0;
    background: none;
    color: $brand;
    font-weight: bold;
    font-family: "Proxima Nova", sans-serif;
    font-size: rem(14);
    line-height: 1.2;
    letter-spacing: 0.21em;
    text-transform: uppercase;
  }


  .ui-datepicker-inline table {
    width: 100%;
    margin: 0;
  }


  .ui-datepicker-inline th,
  .ui-datepicker-inline td {
    font-size: rem(18);
  }


  .ui-datepicker-inline td span,
  .ui-datepicker-inline td a {
    display: block;
    padding: .5em .2em;
    text-align: center;
    text-decoration: none;
  }


  .ui-datepicker-inline .ui-state-default {
    border: 0;
    background: #fff;
    font-weight: normal;
    font-size: rem(18);
    color: #010101;
    
  }


  .ui-datepicker-inline .ui-state-highlight,
  .ui-datepicker-inline .ui-widget-content .ui-state-highlight,
  .ui-datepicker-inline .ui-widget-header .ui-state-highlight {
    border: 0;
    background: #fff;
    color: #010101;
  }


  .ui-datepicker-inline .ui-datepicker-prev,
  .ui-datepicker-inline .ui-datepicker-next {
    top: 50%;
    width: 44px;
    height: 30px;
    transform: translate(0%, -50%);
    border: 0;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 34px 11px;

    &:hover,
    &:focus,
    &:active {
      background-color: #fff;
    }
  }

  .ui-datepicker-inline .ui-datepicker-next {
    background-image: url(../img/icons/golden-arrow-right.png);
  }


  .ui-datepicker-inline .ui-datepicker-prev {
    background-image: url(../img/icons/golden-arrow-left.png);
  }


  .ui-datepicker-inline .ui-datepicker-prev span,
  .ui-datepicker-inline .ui-datepicker-next span {
    display: none;
  }


  .ui-datepicker-inline .ui-datepicker-week-end,
  .ui-datepicker-inline .ui-datepicker-week-end .ui-state-default {
    color: $brand;
  }


  .ui-datepicker-inline td.is-available {
    opacity: 0.3;
  }

}


.calendar-wrapper {
  position: relative;
  width: 100%;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 48em) {
    max-width: 870px;
  }
}


.calendar-wrapper .button {
  display: block;
  width: 228px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 48px;

  @media (min-width: 80em) {
    margin-top: 84px;
  }
}

.calendar__heading {
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  color: #C4C4C4;
  z-index: 1;

  @media (min-width: 62em) {
    position: absolute;
    top: 9px;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 0;
  }
  
}