/*------------------------------------*\
  #BRAND
\*------------------------------------*/

.brand {
  display: block;
  outline: 0;
  text-decoration: none;

  &--hero {
    width: 190px;
    margin-bottom: 45px;

    @media (min-width: 48em) {
      width: 28.8vw;
    }
    @media (min-width: 100em) {
      margin-bottom: 70px;
    }
  }

  &--footer {
    width: 190px;
    height: 71px;
  }
}